import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from 'src/app/service/site.service';
import { environment } from 'src/environments/environment.prod';
import { CartService } from 'src/app/service/cart.service';
declare var $: any;

@Component({
  selector: 'app-site-create',
  templateUrl: './site-create.component.html',
  styleUrls: ['./site-create.component.scss']
})
export class SiteCreateComponent implements OnInit {

  formGroup: FormGroup;
  Edit: any;
  invalid: any;
  alert: boolean = false;
  message: any;
  constructor(private router: Router, private route: ActivatedRoute, private cService: CartService, private service: SiteService, private formBuilder: FormBuilder,) {
    this.route.queryParams.subscribe(
      params => {
        this.Edit = params['Id'];
      });
  }

  ngOnInit() {
    this.createForm();
    if (this.Edit) {
      this.AssignEdit();
    }
  }
  ngAfterViewInit() {
    var _this = this;
    this.select();
    $('[data-toggle="switch"]').bootstrapSwitch('state', true);
    $('[data-toggle="switch"]').bootstrapSwitch('onColor', 'primary');
    $('[data-toggle="switch"]').bootstrapSwitch('onText', 'Active');
    $('[data-toggle="switch"]').on('switchChange.bootstrapSwitch', function (event, state) {
      _this.formGroup.controls.status.setValue(state);
    });
  }
  select() {

  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      status: [true, [Validators.required]],
    });
  }
  AssignEdit() {
    var input = {
      id: this.Edit,
    }
    this.service.ViewSite(input,environment.Token).subscribe((res) => {
      this.formGroup.controls.name.patchValue(res.data.name);
      this.formGroup.controls.address.patchValue(res.data.address);
      this.formGroup.controls.status.patchValue(res.data.status);
      $('[data-toggle="switch"]').bootstrapSwitch('state', res.data.status);
    });
  }
  Submit() {
    var input = {
      name: this.formGroup.controls.name.value,
      address: this.formGroup.controls.address.value,
      status: this.formGroup.controls.status.value,
    }
    if (this.formGroup.invalid) {
      this.formGroup.controls.name.markAsTouched();
      this.formGroup.controls.address.markAsTouched();
    }
    else {
      this.service.AddSite(input,environment.Token).subscribe((res) => {
        if (res.success==true) { 
          
          this.alert = true;
          this.message = "Site created successfully!";
          this.cService.onCartUpdateCondtion(true);
          //this.router.navigate(['/site/index']); 
        }
        if(res.success==false){this.invalid=res.message;}
      });
    }
  }

  Update() {
    var input = {
      id: this.Edit,
      name: this.formGroup.controls.name.value,
      address: this.formGroup.controls.address.value,
      status: this.formGroup.controls.status.value,
    }
    if (this.formGroup.invalid) {
      this.formGroup.controls.name.markAsTouched();
      this.formGroup.controls.address.markAsTouched();
    } else {
      this.service.UpdateSite(input,environment.Token).subscribe((res) => {
        if (res.success==true) { 
          this.alert = true;
          this.message = "Site updated successfully!";
          this.cService.onCartUpdateCondtion(true);
          // this.router.navigate(['/site/index']); 
        }
        if(res.success==false){this.invalid=res.message;}
      });
    }
  }

  cloce()
  {
    this.alert = false;
  }
}

export const environment = {
  production: false,
  Auth: '',
  Token: '',
  Role: null,
  Site: '',
  // BaseUrl: 'http://67.205.148.226:8080/cam',
  BaseUrl: 'http://166.157.172.28:8003/cam',
  //ApiUrl: 'http://67.205.148.226:8080/cam_development'
  ApiUrl: 'https://camapp.furtimtechnologies.com/api'
};

import { Injectable } from '@angular/core';
import * as moment from 'moment';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
 
  Input: any;
  HistoryUrl=[];
  constructor() { }

  ServerDate(val: string){
    if(val && val != ''){
      var date=val.split('/');
      return date[2]+'-'+date[0]+'-'+date[1]+" 00:00:00";
    }else{return '';}
  }
  ServerEndDate(val: string){
    if(val && val != ''){
      var date=val.split('/');
      return date[2]+'-'+date[0]+'-'+date[1]+" 23:59:59";
    }else{return '';}
  }
  ServerCurrendEndDate(val: string){
    if(val && val != ''){
      var date=val.split('/');
      const end = new Date();
      console.log(moment(end).format('hh:mm:ss'));
      return date[2]+'-'+date[0]+'-'+date[1]+" "+moment(end).format('hh:mm:ss');
    }else{return '';}
  }
  Datepick(val: string){
    if(val && val != ''){
      var date=val.slice(0,10).split('-');
      return date[0]+'/'+date[1]+'/'+date[2];
    }else{return '';}
  }

  setInput(val: any){ this.Input = val }
  getInput(){ return this.Input; }

  sorting() {
    $("*[id^=sort]").click(function(e){  
      var e =e.target;
      if($(e).hasClass('orderby')){
        if($(e).hasClass('Asc')){ 
          $(e).removeClass('Asc'); 
          $(e).addClass('Desc'); 
          $('.orderby').next().text("(Desc)");
          $(e).attr("value",$(e).attr("name")+' '+'Desc').trigger('change');
        }
        else { 
          $(e).removeClass('Desc'); 
          $(e).addClass('Asc'); 
          $('.orderby').next().text("(Asc)");
          $(e).attr("value",$(e).attr("name")+' '+'Asc').trigger('change');
        }
      }
      else {
        $('.orderby').next().text("");
        $('.orderby').removeClass('orderby Desc Asc');
        $(e).addClass('orderby Asc');
        $('.orderby').next().text("(Asc)");
        $(e).attr("value",$(e).attr("name")+' '+'Asc').trigger('change');
      } 
    });
  };
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CartService } from 'src/app/service/cart.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-cart-index',
  templateUrl: './cart-index.component.html',
  styleUrls: ['./cart-index.component.scss']
})
export class CartIndexComponent implements OnInit {
  srhNam = new FormControl(''); 
  DataList: any=[];
  pageno = 1;
  to: any;
  from: number;
  type: any = "Descending";
  input = {
    site_id: environment.Role==1?environment.Site:'',  
    sitename: '', 
    cartname: '', 
    waveIpaddress: '',
    flirIpaddress: '',
    status: '',  
    limit: 10, 
    offset: 0, 
    totalcount: '', 
    order: "",
    title: ""
  };
  Role = environment.Role;
  formGroup: FormGroup;
  constructor(private router: Router, private service: CartService, private formBuilder: FormBuilder,private Commonservice: CommonService) {}

  sortTable(element) {
    element.firstElementChild.classList.remove("fa-sort")
    if (this.type == "Ascending") {
      this.type = "Descending";
      element.firstElementChild.classList.add("fa-sort-alpha-down-alt")
    } else {
      this.type = "Ascending"
      element.firstElementChild.classList.add("fa-sort-alpha-up")
    }
    this.input.title = element.getAttribute("data-field");
    this.input.order = this.type;
    this.getTheRecord()
  }

  ngOnInit() { 

    const table = document.querySelector('table');
    table.querySelectorAll('th.sort')
      .forEach((element) => {
        element.addEventListener('click', event => {
          table.querySelectorAll('th.sort')
            .forEach((elements) => {
              elements.firstElementChild.classList.remove("fa-sort-alpha-down-alt", "fa-sort-alpha-up")
              elements.firstElementChild.classList.add("fa-sort")
            });
          this.sortTable(element);
        });
      })
      
    var Url = this.Commonservice.HistoryUrl[this.Commonservice.HistoryUrl.length-2];
    if(Url&&this.Commonservice.getInput()){
      if(Url.split("?")[0]=="/cart/edit"){
        this.input=this.Commonservice.getInput();
        this.pageno=this.input.offset+1;
      }
    }
    this.getTheRecord();
    this.SearchForm();
  }

  SearchForm() {
    this.formGroup = this.formBuilder.group({
      site: [this.input.sitename, ''],
      cart: [this.input.cartname, ''],
      waveIp: [this.input.waveIpaddress, ''],
      flirIp: [this.input.flirIpaddress, ''],
      status: [this.input.status, ''],
    });
    this.formGroup.controls.cart.valueChanges.subscribe( val => {
      this.input.cartname=val;
      this.input.offset=0;
      this.pageno=1;
      this.getTheRecord();
    });
    this.formGroup.controls.site.valueChanges.subscribe( val => {
      this.input.sitename=val;
      this.input.offset=0;
      this.pageno=1;
      this.getTheRecord();
    });
    this.formGroup.controls.waveIp.valueChanges.subscribe( val => {
      this.input.waveIpaddress=val;
      this.input.offset=0;
      this.pageno=1;
      this.getTheRecord();
    });
    this.formGroup.controls.flirIp.valueChanges.subscribe( val => {
      this.input.flirIpaddress=val;
      this.input.offset=0;
      this.pageno=1;
      this.getTheRecord();
    });
    this.formGroup.controls.status.valueChanges.subscribe( val => {
      this.input.status=val;
      this.input.offset=0;
      this.pageno=1;
      this.getTheRecord();
    });
  }

  getTheRecord() {
    this.service.CartIndex(this.input,environment.Token).subscribe((res)=>{
      this.DataList = res;  
      for(var i = 0; i < res.data.length; i++) {
        res.data[i].sno = this.input.limit*this.pageno+i+1-this.input.limit
      }
      this.to = this.pageno * this.input.limit - this.input.limit + res.data.length;
      this.from = this.pageno * this.input.limit - this.input.limit + res.data.length !=0 ? 1 : 0;
      this.input.totalcount  = res.totalcount;
    })
  }

  ngAfterViewInit() {}
  
  EditCart(val){
    this.Commonservice.setInput(this.input);
    this.router.navigate( ['/cart/edit'], { queryParams: { Id: val}});
  }
  Sensor(val){
    this.Commonservice.setInput(this.input);
    this.router.navigate( ['/sensor/view'], { queryParams: { Id: val}});
  }
 
  current(val) {
    this.pageno = val;
    this.input.offset=this.pageno-1;
    this.getTheRecord();
  }

  PerPage(val) {
    this.input.limit = val;
    this.pageno = 1;
    this.input.offset=this.pageno-1;
    this.getTheRecord();
  }

  reset(){
    this.input.waveIpaddress='';
    this.input.flirIpaddress='';
    this.input.cartname='';
    this.input.sitename='';
    this.input.status='';
    this.formGroup.patchValue({
      site: '',
      cart: '',
      waveIp: '',
      flirIp: '',
      status: '',
    }); 
    this.getTheRecord();
  }
}

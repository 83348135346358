import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/pages/common.service';
import { HistoryReportService } from 'src/app/service/history-report.service';
import { TransactionService } from 'src/app/service/transaction.service';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment.prod';
import { HomeService } from 'src/app/service/home.service';
declare var $: any;

@Component({
  selector: 'app-history-report-index',
  templateUrl: './history-report-index.component.html',
  styleUrls: ['./history-report-index.component.scss']
})
export class HistoryReportIndexComponent implements OnInit {

  srhSite = new FormControl('');
  srhTran = new FormControl('');
  Sdate = new FormControl('',Validators.required);
  Edate = new FormControl('',Validators.required);
  DataList: any=[];
  pageno = 1;
  to: any;
  from: number;
  Role=environment.Role;
  type: any = "Descending";
  input = {
    site_id: this.Role==1?environment.Site:"",
    location_id: '',
    fromdate: '',
    todate: '',
    status: '',
    limit: 10,
    offset: 0,
    totalcount: '',
    order: "",
    title: ""
  };
  formGroup: FormGroup;
  invalidDate: boolean;
  locList: any;
  siteList: any;
  constructor(private formBuilder: FormBuilder, private router: Router, private service: HistoryReportService,private Commonservice: CommonService, private Transervice: TransactionService, private Usrservice: UserService, private homeService: HomeService) {}

  sortTable(element) {
    element.firstElementChild.classList.remove("fa-sort")
    if (this.type == "Ascending") {
      this.type = "Descending";
      element.firstElementChild.classList.add("fa-sort-alpha-down-alt")
    } else {
      this.type = "Ascending"
      element.firstElementChild.classList.add("fa-sort-alpha-up")
    }
    this.input.title = element.getAttribute("data-field");
    this.input.order = this.type;
    this.getTheRecord()
  }

  ngOnInit() {

    const table = document.querySelector('table');
    table.querySelectorAll('th.sort')
      .forEach((element) => {
        element.addEventListener('click', event => {
          table.querySelectorAll('th.sort')
            .forEach((elements) => {
              elements.firstElementChild.classList.remove("fa-sort-alpha-down-alt", "fa-sort-alpha-up")
              elements.firstElementChild.classList.add("fa-sort")
            });
          this.sortTable(element);
        });
      })
      
    this.Usrservice.SiteList(localStorage.getItem('token')).subscribe((res) => {
      this.siteList=res.data;
    });
    this.getTheRecord();
    this.SearchForm();
    this.InitDate();
    this.getLoc(environment.Site);
  }

  getLoc(site){
    this.Transervice.LocList({site_id: site},environment.Token).subscribe((res)=>{
      this.locList=res.data;
    });
  }

  getTheRecord() {
    this.service.HistoryIndex(this.input,environment.Token).subscribe((res)=>{
      this.DataList = res;
      for(var i = 0; i < res.data.length; i++) {
        res.data[i].sno = this.input.limit*this.pageno+i+1-this.input.limit
      }
      this.to = this.pageno * this.input.limit - this.input.limit + res.data.length;
      this.from = this.pageno * this.input.limit - this.input.limit + res.data.length !=0 ? 1 : 0;
      this.input.totalcount  = res.totalcount;
    })
  }

  InitDate() {
    var _this=this;
    $('#history_Sdate').datepicker({ orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#history_Edate').datepicker({ orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#history_Sdate').change(function () {
      _this.formGroup.controls.Sdate.patchValue($('#history_Sdate').val());
      _this.checkDates();
    });
    $('#history_Edate').change(function () {
      _this.formGroup.controls.Edate.patchValue($('#history_Edate').val());
      _this.checkDates();
    });
    $('#history_Edate').datepicker("setDate", new Date(this.formGroup.controls.Edate.value));
    $('#history_Sdate').datepicker("setDate", new Date(this.formGroup.controls.Sdate.value));
  }

  checkDates() {
    let fromDate = this.Commonservice.ServerDate(this.formGroup.controls.Sdate.value);
    let toDate = this.Commonservice.ServerDate(this.formGroup.controls.Edate.value);
    this.invalidDate=false
    if(toDate && fromDate){
      if(new Date(fromDate) > new Date(toDate)){
        this.invalidDate=true
      } 
      else {
        this.input.fromdate=this.Commonservice.ServerDate(this.formGroup.controls.Sdate.value);
        this.input.todate=this.Commonservice.ServerDate(this.formGroup.controls.Edate.value);
        this.getTheRecord();
      }
    }
    if(toDate || fromDate){
      this.formGroup.controls.Sdate.setValidators([Validators.required]);
      this.formGroup.controls.Edate.setValidators([Validators.required]);
      this.formGroup.controls.Sdate.updateValueAndValidity();
      this.formGroup.controls.Edate.updateValueAndValidity();
    } else {
      this.formGroup.controls.Sdate.clearValidators();
      this.formGroup.controls.Edate.clearValidators();
    }
  }

  SearchForm() {
    this.formGroup = this.formBuilder.group({
      site: [this.input.site_id, ''],
      location: [this.input.location_id, ''],
      Sdate: [this.input.fromdate, ''],
      Edate: [this.input.todate, ''],
      status: [this.input.status, ''],
    });
    if(this.Role==-1||this.Role==0){
      this.formGroup.controls.site.valueChanges.subscribe( val => {
        this.input.site_id=val;
        this.input.offset=0;
        this.pageno=1;
        this.getTheRecord();
        this.getLoc(val);
      });
    }
    this.formGroup.controls.location.valueChanges.subscribe( val => {
      this.input.location_id=val;
      this.input.offset=0;
      this.pageno=1;
      this.getTheRecord();
    });
    this.formGroup.controls.status.valueChanges.subscribe( val => {
      this.input.status=val;
      this.input.offset=0;
      this.pageno=1;
      this.getTheRecord();
    });
  }

  ngAfterViewInit() {
    var _this=this;
    $('#history_Sdate').datepicker({ orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#history_Edate').datepicker({ orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#history_Sdate').change(function () {
      _this.Sdate.patchValue($('#history_Sdate').val());
    });
    $('#history_Edate').change(function () {
      _this.Edate.patchValue($('#history_Edate').val());
    });
  }

  Search(){
    this.Sdate.markAsTouched();
    this.Edate.markAsTouched();
    var input = {
      token: environment.Token,
      transaction_fromdate: this.Commonservice.ServerDate(this.Sdate.value),
      transaction_toDate: this.Commonservice.ServerDate(this.Edate.value),
    }
  }

  ViewHistory(val){
    this.router.navigate( ['/history/view'], { queryParams: { Id: val.id, name: val.transactionName}});
  }

  current(val) {
    this.pageno = val;
    this.input.offset=this.pageno-1;
    this.getTheRecord();
  }

  PerPage(val) {
    this.input.limit = val;
    this.pageno = 1;
    this.input.offset=this.pageno-1;
    this.getTheRecord();
  }

  reset(){
    if(this.Role==-1||this.Role==0){ this.input.site_id=''; this.formGroup.controls.site.patchValue(''); }
    this.input.location_id='';
    this.input.fromdate='';
    this.input.todate='';
    this.input.status='';
    this.formGroup.controls.Sdate.clearValidators();
    this.formGroup.controls.Edate.clearValidators();
    this.formGroup.patchValue({
      location: '',
      Sdate: '',
      Edate: '',
      status: '',
    }); 
  }

  dateconvert(datevalue) {
    if (datevalue) {
      return this.homeService.dateconvert(datevalue)
    } else {
      return "";
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { 
  MatIconModule,
  MatCardModule,
  MatButtonModule,
  MatProgressSpinnerModule } from '@angular/material';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs'; 
import { ChartsModule } from 'ng2-charts';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';  
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { UserIndexComponent } from './pages/user-management/user-index/user-index.component';
import { UserCreateComponent } from './pages/user-management/user-create/user-create.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SiteCreateComponent } from './pages/site-management/site-create/site-create.component';
import { SiteIndexComponent } from './pages/site-management/site-index/site-index.component';
import { CartCreateComponent } from './pages/cart-management/cart-create/cart-create.component';
import { CartIndexComponent } from './pages/cart-management/cart-index/cart-index.component';
import { LocationCreateComponent } from './pages/location-management/location-create/location-create.component';
import { LocationIndexComponent } from './pages/location-management/location-index/location-index.component';
import { UserViewComponent } from './pages/user-management/user-view/user-view.component';
import { SiteViewComponent } from './pages/site-management/site-view/site-view.component';
import { CommonService } from './pages/common.service';
import { TransactionCreateComponent } from './pages/transaction/transaction-create/transaction-create.component';
import { TransactionIndexComponent } from './pages/transaction/transaction-index/transaction-index.component';
import { HistoryReportIndexComponent } from './pages/report/history-report/history-report-index/history-report-index.component';
import { HistoryReportViewComponent } from './pages/report/history-report/history-report-view/history-report-view.component';
import { LogIndexComponent } from './pages/report/log-report/log-index/log-index.component';
import { LogViewComponent } from './pages/report/log-report/log-view/log-view.component';
import { EmptyComponent } from './pages/layout/empty/empty.component';
import { DatePipe } from '@angular/common';
import { CartSensorComponent } from './pages/cart-management/cart-sensor/cart-sensor.component';
import { TransactionViewComponent } from './pages/transaction/transaction-view/transaction-view.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, 
    LoginComponent,
    LayoutComponent,
    UserIndexComponent,
    UserCreateComponent,
    AppComponent,
    HomeComponent, 
    LoginComponent,
    LayoutComponent,
    UserIndexComponent,
    UserCreateComponent,
    SiteCreateComponent,
    SiteIndexComponent,
    CartCreateComponent,
    CartIndexComponent,
    LocationCreateComponent,
    LocationIndexComponent,
    UserViewComponent,
    TransactionCreateComponent,
    TransactionIndexComponent,
    SiteViewComponent,
    LogIndexComponent,
    LogViewComponent,
    HistoryReportIndexComponent,
    HistoryReportViewComponent,
    EmptyComponent,
    CartSensorComponent,
    TransactionViewComponent,
    DateTimePickerComponent,
  ],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    FormsModule,
    ChartsModule,    
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgbModule
  ],
  providers: [CommonService, {provide:LocationStrategy, useClass: HashLocationStrategy},DatePipe],
  bootstrap: [AppComponent], 
})
export class AppModule {
 
 }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  constructor(private httpClient: HttpClient) { }

  json() {
    return this.httpClient.get<any>('./assets/data.json')
      .pipe(tap((response: any) => {
       // this.appTitle = response.title;
      }));
  }
}

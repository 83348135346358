import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-log-index',
  templateUrl: './log-index.component.html',
  styleUrls: ['./log-index.component.scss']
})
export class LogIndexComponent implements OnInit {

  srhNam = new FormControl(''); 
  DataList: any=[];
  pageno = 1;
  to: any;
  from: number;
  input = {
    site_id: environment.Role==1?environment.Site:'',  
    username: '', 
    site_name: '', 
    email: '',  
    active: '',  
    roleid: '',
    limit: 10, 
    offset: 0, 
    totalcount: '', 
  };
  Role=environment.Role;
  
  constructor() { }

  ngOnInit() {

  }

  view(val){
    // this.router.navigate( ['/log/view'], { queryParams: { Id: val}});
  }
 
  current(val) {
    this.pageno = val;
    this.input.offset=this.pageno-1;
    // this.getTheRecord();
  }

  PerPage(val) {
    this.input.limit = val;
    this.pageno = 1;
    this.input.offset=this.pageno-1;
    // this.getTheRecord();
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from 'src/app/service/transaction.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from '../../common.service';
declare var $: any;

@Component({
  selector: 'app-transaction-create',
  templateUrl: './transaction-create.component.html',
  styleUrls: ['./transaction-create.component.scss']
})
export class TransactionCreateComponent implements OnInit {

  formGroup: FormGroup;
  Edit: any;
  status:any;
  fisrtEdit:boolean = true;
  locList: any;
  cartList: any;
  invalid: string;
  invalidDate=false;
  alert: boolean = false;
  message: any;
  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,private Commonservice: CommonService, private router: Router, private service: TransactionService,) { 
    this.route.queryParams.subscribe(
      params => {
        this.Edit =  params['Id']; 
        this.status =  params['status']; 
    });
    this.service.LocList({site_id: environment.Site},environment.Token).subscribe((res)=>{
      this.locList=res.data;
    });
    this.service.CartList({site_id: environment.Site},environment.Token).subscribe((res)=>{
      this.cartList=res.data;
    });
  }

  ngOnInit() {
    this.createForm();
    console.log(this.status);
    if(this.Edit){
      this.AssignEdit();
    }
  }
  ngAfterViewInit() { 
    var _this=this;
    this.select();
    if(!this.Edit){this.InitDate();}
    $('[data-toggle="switch"]').bootstrapSwitch('state', true);
    $('[data-toggle="switch"]').bootstrapSwitch('onColor', 'primary');
    console.log(_this.status);
    if(_this.status == 0 || _this.status == undefined){
      $('[data-toggle="switch"]').bootstrapSwitch('onText', 'Inactive');
      $('[data-toggle="switch"]').bootstrapSwitch('offText', 'Monitoring');
      $('[data-toggle="switch"]').on('switchChange.bootstrapSwitch', function (event, state) {
        if(state == false){
           console.log(state,2)
          _this.formGroup.controls.status.setValue(2);
         }else{
          console.log(state,0)
          _this.formGroup.controls.status.setValue(0);
         }
      });
    }else{
      $('[data-toggle="switch"]').bootstrapSwitch('offText', 'Inactive');
        $('[data-toggle="switch"]').bootstrapSwitch('onText', 'Monitoring');
      $('[data-toggle="switch"]').on('switchChange.bootstrapSwitch', function (event, state) {
        if(state == true){
           console.log(state,2)
          _this.formGroup.controls.status.setValue(2);
         }else{
          console.log(state,0)
          _this.formGroup.controls.status.setValue(0);
         }
      });
    }
    // $('#user').change(function () {
    //   if($("#user option:selected").length == 0){ 
    //     $('#user').next().addClass("is-invalid"); $('#user').next().css("background-position", "center right calc(.375em + 1.1875rem)");
    //     _this.formGroup.get('user').markAsTouched();
    //   } else {
    //     $('#user').next().removeClass("is-invalid"); $('#user').next().css("background-position", "");
    //     _this.formGroup.get('user').markAsUntouched();
    //   }
    // });
  }

  InitDate() {
    var _this=this;
    $('#assign_Sdate').datepicker({ startDate: new Date(), orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#assign_Edate').datepicker({ startDate: new Date(), orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#assign_Sdate').change(function () {
      _this.formGroup.controls.Sdate.patchValue($('#assign_Sdate').val());
      _this.checkDates();
    });
    $('#assign_Edate').change(function () {
      _this.formGroup.controls.Edate.patchValue($('#assign_Edate').val());
      _this.checkDates();
    });
    $('#assign_Edate').datepicker("setDate", new Date(this.formGroup.controls.Edate.value));
    // $('#assign_Sdate').datepicker("setDate", new Date(this.formGroup.controls.Sdate.value));
  }

  checkDates() {
    let fromDate = this.Commonservice.ServerDate(this.formGroup.controls.Sdate.value);
    let toDate = this.Commonservice.ServerDate(this.formGroup.controls.Edate.value);
    this.invalidDate=false
    if(toDate && fromDate){
      if(new Date(fromDate) > new Date(toDate)){
        this.invalidDate=true
      }
    }
  }

  select(){ 
    var _this=this;
    setTimeout(function(){ 
      $("#user").multiselect('destroy');
      $('#user').multiselect({ includeSelectAllOption: true, });
      if(!_this.Edit){ 
        var siteOptions=[];
        _this.service.UserList({site_id: environment.Site},environment.Token).subscribe((res)=>{
          res.data.forEach((o, i) => {
            var ordersData = {
              label: o.name,
              value: o.id,
              selected: false,
            };
            siteOptions.push(ordersData);
          });
          $('#user').multiselect('dataprovider', siteOptions);
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("input-group");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("form-control");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("btn");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("dropdown-toggle");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().css("padding-right", "0.5rem");
        });
      }
    },100);
  }

  createForm(){
    this.formGroup = this.formBuilder.group({
      transaction : ['', Validators.required], 
      location: ['', Validators.required],
      cart : ['', Validators.required],  
      Sdate : ['', ''],  
      Edate : ['', ''],  
      status: [0, [Validators.required]],   
      user : ['', ''],
      cartname : ['', ''],
    });
  }

  AssignEdit(){
    var input = {
      id: this.Edit,
    }
    this.formGroup.controls.transaction.disable();
    this.formGroup.controls.cart.disable();
    // this.formGroup.controls.Sdate.disable();
    var datePipe = new DatePipe("en-US");
    this.service.TransView(input,environment.Token).subscribe((res)=>{
      this.formGroup.controls.cart.patchValue(res.data.cart_id);
      this.formGroup.controls.cartname.patchValue(res.data.cartname);
      this.formGroup.controls.location.patchValue(res.data.locatoin_id);
      this.formGroup.controls.transaction.patchValue(res.data.transactionName);
      this.formGroup.controls.status.patchValue(res.data.status);
      // $('[data-toggle="switch"]').bootstrapSwitch('state', res.data.status); 
      // this.formGroup.controls.status.patchValue(res.data.status!=0?true:false);
      var s = (this.Commonservice.Datepick(res.data.transaction_fromdate)).split("/");
      var Sd=s[1]+"/"+s[2]+"/"+s[0];
      this.formGroup.controls.Sdate.patchValue(Sd);
      this.formGroup.controls.Edate.patchValue(this.Commonservice.Datepick(res.data.transaction_toDate));
      this.InitDate();
      var siteOptions = [];
      this.service.UserList({site_id: environment.Site},environment.Token).subscribe((val)=>{
        val.data.forEach((o, i) => {
          var ordersData = {
            label: o.name,
            value: o.id,
            selected: false,
          };
          res.data.user_id.forEach((s) => {
            if (s == o.id) { 
              ordersData.label = o.name;
              ordersData.value = o.id;
              ordersData.selected = true;
            }
          });
          siteOptions.push(ordersData);
        });
        $('#user').multiselect('dataprovider', siteOptions);
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("input-group");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("form-control");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("btn");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("dropdown-toggle");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().css("padding-right", "0.5rem");
      });
      $('#location').attr("disabled", true); 
    });
  }  

  Submit(){
    this.invalid='';
    $('#wait').attr("disabled", true); setTimeout(() => { $('#wait').attr("disabled", false); }, 5000);
    var _this=this;
    var input = {
      site_id: ""+environment.Site,
      cart_id: this.formGroup.controls.cart.value,
      locatoin_id: this.formGroup.controls.location.value,
      transactionName: this.formGroup.controls.transaction.value,
      user_id: [],
      status: this.formGroup.controls.status.value,
    };
    if($("#user option:selected").length == 0){ 
      // $('#user').next().addClass("is-invalid"); $('#user').next().css("background-position", "center right calc(.375em + 1.1875rem)");
      // _this.formGroup.get('user').markAsTouched();
    } else {
      $("#user option:selected").each(function () {
        input.user_id.push($(this).val());
      });
      // $('#user').next().removeClass("is-invalid"); 
      // _this.formGroup.get('user').markAsUntouched();
    }
    this.formGroup.controls.cart.markAsTouched();
    this.formGroup.controls.transaction.markAsTouched();
    this.formGroup.controls.location.markAsTouched();
    this.formGroup.controls.Sdate.markAsTouched();
    this.formGroup.controls.Edate.markAsTouched();
    this.formGroup.controls.status.markAsTouched();
    if(this.formGroup.valid){
      console.log(input);
      this.service.TransAdd(input,environment.Token).subscribe((res) => {
        if (res.success==true) { 
          // this.router.navigate(['/transaction/index']); 
          this.alert = true;
          this.message = "Transaction created successfully!"
        }
        if(res.success==false){this.invalid=res.message;}
      });
    }
  }

  Update(confirm){
    var _this=this;
    $('#wait').attr("disabled", true); setTimeout(() => { $('#wait').attr("disabled", false); }, 2000);
    var input = {
      id: this.Edit,
      site_id: ""+environment.Site,
      cart_id: this.formGroup.controls.cart.value,
      locatoin_id: this.formGroup.controls.location.value,
      transactionName: this.formGroup.controls.transaction.value,
      user_id: [],
      status: this.formGroup.controls.status.value,
    };
    if(input.status!=0){
      if($('#end').prop("checked")==true){ input.status=3 }
    }
    if($("#user option:selected").length != 0){ 
      $("#user option:selected").each(function () {
        input.user_id.push($(this).val());
      });
    }
    this.formGroup.controls.location.markAsTouched();
    this.formGroup.controls.Edate.markAsTouched();
    if(confirm==false&&input.status==3) { $('#Trans_comp').modal('show'); }
    if(this.formGroup.valid && input.status!=3){
      console.log(input,"2");
      this.service.TransUpdate(input,environment.Token).subscribe((res) => {
        if (res.success==true) { 
          // this.router.navigate(['/transaction/index']); 
          this.alert = true;
          this.message = "Transaction updated successfully!"
        }
        if(res.success==false){this.invalid=res.message;}
      });
    }
    if(this.formGroup.valid && input.status==3 && confirm==true){
      console.log(input,'3');
      this.service.TransUpdate(input,environment.Token).subscribe((res) => {
        if (res.success==true) { this.router.navigate(['/transaction/index']); }
        if(res.success==false){this.invalid=res.message;}
      });
    }
  }

  cloce()
  {
    this.alert = false;
  }
}

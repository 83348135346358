import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/pages/common.service';
import { HistoryReportService } from 'src/app/service/history-report.service';
import { environment } from 'src/environments/environment.prod';
import { values } from 'underscore';
declare var $: any;
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-history-report-view',
  templateUrl: './history-report-view.component.html',
  styleUrls: ['./history-report-view.component.scss']
})
export class HistoryReportViewComponent implements OnInit {
  input = {
    transaction_id: "",
    fromdate: "",
    todate: "",
  }
  DataList=[];
  invalidDate: boolean;
  transaction_name: any;
  Nfound: boolean;
  constructor(private router: Router, private route: ActivatedRoute, private service: HistoryReportService, private Commonservice: CommonService) { 
    this.route.queryParams.subscribe( params => {
      this.input.transaction_id = params['Id'];
      this.transaction_name = params['name'];
    });
  }

  ngOnInit() {
    // this.getTheRecord();
    $('#submit').attr("disabled", true);
  }

  getTheRecord() {
    this.service.Historyview(this.input,environment.Token).subscribe((res)=>{
      this.DataList=[];
      res.data.forEach((e,i) => {
        var fromdate=e.fromdate.slice(0,10);
        if(i!=0){
          if(fromdate==res.data[i-1].fromdate.slice(0,10)){
            this.DataList[fromdate].push(e);
          }
          else {
            this.DataList[fromdate]=[e];
          }
        } else { this.DataList[fromdate]=[e]; }
      });
      if(res.data.length==0){ this.Nfound=true; } else { this.Nfound=false; }
    });
  }
 
  ngAfterViewInit() {
    var _this=this;
    $('#history_v_Sdate').datepicker({ orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#history_v_Edate').datepicker({ orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#history_v_Sdate').change(function () {
      _this.input.fromdate = _this.Commonservice.ServerDate($('#history_v_Sdate').val());
      _this.checkDates();
    });
    $('#history_v_Edate').change(function () {
      _this.input.todate = _this.Commonservice.ServerEndDate($('#history_v_Edate').val());
      _this.checkDates();
    });
  }
  checkDates() {
    let fromDate = this.input.fromdate;
    let toDate = this.input.todate;
    this.invalidDate=false
    if(toDate && fromDate){
      if(new Date(fromDate) > new Date(toDate)){
        this.invalidDate=true
        $('#submit').attr("disabled", true);
      } 
      else { $('#submit').attr("disabled", false); }
    }
  } 

  collabe(e,stat){
    if(stat=="open"){ 
      $('.'+'fa-caret-down.'+e).css("display", "block"); 
      $('.'+'fa-caret-right.'+e).css("display", "none"); 
      $('.'+'card-body.'+e).css("display", ""); 
    }
    else { 
      $('.'+'fa-caret-down.'+e).css("display", "none"); 
      $('.'+'fa-caret-right.'+e).css("display", "block"); 
      $('.'+'card-body.'+e).css("display", "none"); 
    }
  }

  ExportXl(e){
    let element = document.getElementById(e); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
   
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.transaction_name+'_'+this.Commonservice.Datepick(e)+'.xlsx');
    
  }

  reset(){
    this.input.fromdate="";
    this.input.todate="";
    $('#history_v_Sdate').datepicker('setDate', null);
    $('#history_v_Edate').datepicker('setDate', null);
    $('#history_v_Sdate').datepicker('update');
    $('#history_v_Edate').datepicker('update');
    // this.getTheRecord();
    $('#submit').attr("disabled", true);
    this.DataList=[];
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  URL = environment.ApiUrl;
  constructor(private http: HttpClient) { }

  UserIndex(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/userindex', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  AddUser(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/adduser', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  ViewUser(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/userview', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  UpdateUser(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/edituser', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  SiteList(Token: any): Observable<any> { 
    return this.http.post(this.URL+'/sitelist',"",{headers: new HttpHeaders({'Authorization': 'Bearer ' + Token})})
  }
}

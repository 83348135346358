import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import {map} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  URL = environment.ApiUrl;
  constructor(private http: HttpClient) { }
  
 
  UserList(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/userlist', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  LocList(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/locationlist', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  CartList(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/cartlist', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  TransAdd(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/addtransaction', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  TransView(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transactionview', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  TransUpdate(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/edittransaction', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  TransDelete(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transactiondelete', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  TransactionIndex(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transactionindex', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  NotiAdd(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/notificationadd', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  CamList(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/activetransaction', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
}

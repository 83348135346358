import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from '../../common.service';
declare var $: any;

@Component({
  selector: 'app-user-index',
  templateUrl: './user-index.component.html',
  styleUrls: ['./user-index.component.scss']
})
export class UserIndexComponent implements OnInit {

  srhNam = new FormControl('');
  srhRol = new FormControl('');
  srhSite = new FormControl('');
  srhStat = new FormControl('');
  srhMail = new FormControl('');
  DataList: any = [];
  pageno = 1;
  to: any;
  from: number;
  input = {
    site_id: environment.Role == 1 || environment.Role == -1 ? environment.Site : '',
    username: '',
    firstname: '',
    lastname: '',
    sitename: '',
    email: '',
    active: '',
    roleid: '',
    limit: 10,
    offset: 0,
    totalcount: '',
    order: "",
    title: ""
  };
  Role = environment.Role;
  formGroup: FormGroup;
  type: any = "Descending";
  constructor(private router: Router, private service: UserService, private formBuilder: FormBuilder, private Commonservice: CommonService, private _ngZone: NgZone) { }
  sortTable(element) {
    element.firstElementChild.classList.remove("fa-sort")
    if (this.type == "Ascending") {
      this.type = "Descending";
      element.firstElementChild.classList.add("fa-sort-alpha-down-alt")
    } else {
      this.type = "Ascending"
      element.firstElementChild.classList.add("fa-sort-alpha-up")
    }
    this.input.title = element.getAttribute("data-field");
    this.input.order = this.type;
    this.getTheRecord()
  }

  ngOnInit() {

    const table = document.querySelector('table');
    table.querySelectorAll('th.sort')
      .forEach((element) => {
        element.addEventListener('click', event => {
          this._ngZone.run(() => {
            table.querySelectorAll('th.sort')
              .forEach((elements) => {
                elements.firstElementChild.classList.remove("fa-sort-alpha-down-alt", "fa-sort-alpha-up")
                elements.firstElementChild.classList.add("fa-sort")
              });
            this.sortTable(element);
          })


        });
      })
    var Url = this.Commonservice.HistoryUrl[this.Commonservice.HistoryUrl.length - 2];
    if (Url && this.Commonservice.getInput()) {
      if (Url.split("?")[0] == "/user/edit") {
        this.input = this.Commonservice.getInput();
        this.pageno = this.input.offset + 1;
      }
    }
    this.getTheRecord();
    this.SearchForm();
  }

  SearchForm() {
    this.formGroup = this.formBuilder.group({
      name: [this.input.username, ''],
      firstname: [this.input.firstname, ''],
      lastname: [this.input.lastname, ''],
      email: [this.input.email, ''],
      site: [this.input.sitename, ''],
      role: [this.input.roleid, ''],
      status: [this.input.active, ''],
    });
    this.formGroup.controls.name.valueChanges.subscribe(val => {
      this.input.username = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.firstname.valueChanges.subscribe(val => {
      this.input.firstname = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.lastname.valueChanges.subscribe(val => {
      this.input.lastname = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.email.valueChanges.subscribe(val => {
      this.input.email = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.role.valueChanges.subscribe(val => {
      this.input.roleid = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.status.valueChanges.subscribe(val => {
      this.input.active = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.site.valueChanges.subscribe(val => {
      this.input.sitename = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
  }

  getTheRecord() {
    this.service.UserIndex(this.input, environment.Token).subscribe((res) => {
      this.DataList = res;
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].sno = this.input.limit * this.pageno + i + 1 - this.input.limit
      }
      this.to = this.pageno * this.input.limit - this.input.limit + res.data.length;
      this.from = this.pageno * this.input.limit - this.input.limit + res.data.length != 0 ? 1 : 0;
      this.input.totalcount = res.totalcount;
    })
  }

  ngAfterViewInit() {
    this.Commonservice.sorting();
    $("*[id^=sort]").change(function (e) {
      console.log($(e.target).attr("value"));
    });
  }

  EditUser(val) {
    this.Commonservice.setInput(this.input);
    this.router.navigate(['/user/edit'], { queryParams: { Id: val } });
  }

  current(val) {
    this.pageno = val;
    this.input.offset = this.pageno - 1;
    this.getTheRecord();
  }

  PerPage(val) {
    this.input.limit = val;
    this.pageno = 1;
    this.input.offset = this.pageno - 1;
    this.getTheRecord();
  }

  reset() {
    this.input.active = '';
    this.input.email = '';
    this.input.roleid = '';
    this.input.sitename = '';
    this.input.firstname = '';
    this.input.lastname = '';
    this.formGroup.patchValue({
      name: '',
      firstname: '',
      lastname: '',
      email: '',
      site: '',
      role: '',
      status: '',
    });
    this.getTheRecord();
  }
}

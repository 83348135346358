import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + environment.Token })
  };
  URL: any;
  // constructor(private http : HttpClient) { this.URL = environment.BaseUrl+'/services/cart' }
  constructor(private http: HttpClient) { this.URL = environment.ApiUrl + '/services/cart' }

  GetAllTabs(token: string): Observable<any> {
    return this.http.post(this.URL + '/camConfig', '', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }

  GetToken(camId: string, token: string): Observable<any> {
    return this.http.get(this.URL + '/getToken/' + camId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }

  CreateLog(camId: string, token: string): Observable<any> {
    return this.http.post(this.URL + '/createLog/' + camId, '', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }

  GetAllAlerts(camId: string, token: string): Observable<any> {
    return this.http.get(this.URL + '/getAllAlerts/' + camId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }
  GetLiveChartsDefault(transactionId: string, token: string): Observable<any> {
    const getUrl = `${environment.ApiUrl}/transaction/intervalinminutes?id=${transactionId}`;
    return this.http.get(getUrl, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }
  GetLiveChartsLoop(transactionId: string,index: string,token: string): Observable<any> {
    const getUrl = `${environment.ApiUrl}/transaction/intervalinminutes?id=${transactionId}&index=${index}`;
    return this.http.get(getUrl, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }

  GetHistoryCharts(transactionId: string,date: any, token: string): Observable<any> {
    const getHistoryUrl = `${environment.ApiUrl}/transaction/intervalbetweendates?id=${transactionId}&fromDate=${date.fromdate}&toDate=${date.todate}`;
    return this.http.get(getHistoryUrl, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }
  GetWaveAlarms(camId: string, token: string): Observable<any> {
    return this.http.get(this.URL + '/getWaveEvents/' + camId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }

  GetAllLogs(camId: string, token: string): Observable<any> {
    return this.http.get(this.URL + '/getAllLogs/' + camId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }

  UpdateLog(input: any, token: string): Observable<any> {
    return this.http.post(this.URL + '/updateLog/' + input.id, input, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) });
  }


  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['Start Time', 'End Time', 'Duration'], ['startTime', 'endTime', 'duration']);
    //console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, header, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in header) {
      row += header[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];


        if (array[i][head] != 'undefined' && array[i][head] != 'null' && array[i][head] != null) {
          line += ',' + array[i][head];
        } else {
          line += ',' + '';
        }

      }
      str += line + '\r\n';
    }
    return str;
  }

  dateconvert(datevalue) {
    var dateObj = datevalue.split('-');
    var month = dateObj[0];
    var day = dateObj[1];
    var year = dateObj[2];
    var newdate = month + "-" + day + "-" + year.substring(0, 4);
    // var month = dateObj[1];
    // var day = dateObj[2];
    // var year = dateObj[0];
    // var newdate = month + "-" + day.substring(0, 2) + "-" + year;
    return newdate;
  }
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + environment.Token })
  };;

  URL: string;

  constructor(private http: HttpClient) { this.URL = environment.ApiUrl }

  LocationIndex(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/locationindex', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  AddLocation(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/addlocation', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  ViewLocation(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/locationview', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  EditLocation(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/editlocation', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { CartService } from 'src/app/service/cart.service';
import { environment } from 'src/environments/environment.prod';
import { Location } from '@angular/common';
import { filter } from 'rxjs/internal/operators/filter';
import { CommonService } from '../common.service';
declare var $: any;
import { version } from '../../../../package.json';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public version: string = version;

  usrName=localStorage.getItem('userName');
  Role=environment.Role;
  siteList: any;
  site=environment.Site;
  path: string;
  home: string;
  constructor(private location: Location,public router: Router,private service: UserService,private cService: CartService,private Commonservice: CommonService,) {
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.Commonservice.HistoryUrl.push(event.url);
      // -------------------------------------- //
      this.path=location.path();
      if(location.path()=="/home"||location.path()==""){
        this.home="/home";
      } else { this.home="";}
      $('.modal-backdrop.show').css("display","none");
      if(this.path.split('?').length==1){ $('#sites').attr("disabled", false); }
      else { $('#sites').attr("disabled", true); }
    });
   }

  ngOnInit(): void {
    if (this.cService.subsVar==undefined) {
      this.cService.subsVar = this.cService.cartUpdateCondtion.subscribe((index:boolean) => {
       this.getCart();
      });
    }
    this.getCart();
  }
  getCart(){
    this.service.SiteList(localStorage.getItem('token')).subscribe((res) => {
      if(res.data.length!=0){
        this.siteList=res.data;
        environment.Site=res.data[0].id;
      }
      // this.site=environment.Site;
      // this.ChangeSite(res.data[0].id);
    });
  }

  Logout(){
    localStorage.clear();
    $('#logoutModal').modal('hide');
    this.router.navigate(['/login']);
  }

  ChangeSite(val){
    environment.Site=val;
    this.router.navigateByUrl('empty', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.path]);
    }); 
  }

  ngAfterViewInit() {
    var _this=this;
    $('#accordionSidebar').addClass('toggled');
    $('.sidebarCollab').click(function(){  
      if($('#accordionSidebar').hasClass('toggled')){ $('#accordionSidebar').removeClass('toggled'); } 
      else{ $('#accordionSidebar').addClass('toggled'); }
    });
    $('.nav-link').click(function(){  
      $('.nav-link').closest('li.nav-item').removeClass('active');
      if($(this).closest('div.nav-item').hasClass('active')){ $(this).closest('div.nav-item').removeClass('active'); } 
      else { $(this).closest('div.nav-item').addClass('active'); }
      $(this).closest('li.nav-item').addClass('active');
    });
  }

  hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  URL = environment.ApiUrl;
  constructor(private http: HttpClient) { }

  thermalAdd(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/save/thermalsensor', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  thermalView(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/view/thermalsensor', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  thermalUpdate(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/edit/thermalsensor', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  thermalDelete(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/delete/thermalsensor', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  thermalTest(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/test/thermalsensor', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  smokeView(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/view/smokesensor', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  smokeUpdate(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/edit/smokesensor', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  smoketView(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transaction/smokeview', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  smoketUpdate(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transaction/smokeupdate', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  smoketEdit(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transaction/smokeedit', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  thermalTranView(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transaction/sensorview', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  thermalTranAdd(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transaction/sensorupdate', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  thermalTranUpdate(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/transaction/sensoredit', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
}


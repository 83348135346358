import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SensorService } from 'src/app/service/sensor.service';
import { environment } from 'src/environments/environment.prod';
declare var $: any;

@Component({
  selector: 'app-cart-sensor',
  templateUrl: './cart-sensor.component.html',
  styleUrls: ['./cart-sensor.component.scss']
})
export class CartSensorComponent implements OnInit {
  Edit: any;
  formGroup: FormGroup;
  smokeList: any;
  thermalList: any;
  termalsuccess: boolean = false;
  alert: boolean = false;
  testtermalresult: any;
  deleteid: any;
  thermalerr:any;


  constructor(private route: ActivatedRoute, private service: SensorService, private formBuilder: FormBuilder, ) {
    this.route.queryParams.subscribe(params => {
      this.Edit = params['Id'];
    });
    this.createForm();
  }

  ngOnInit() {
    this.smokeData();
    this.thermalData();
    this.formGroup.get('type').valueChanges.subscribe(value => {
      this.thermalerr = "";
    });
    this.formGroup.get('tag').valueChanges.subscribe(value => {
      this.thermalerr = "";
    });
    this.formGroup.get('index').valueChanges.subscribe(value => {
      this.thermalerr = "";
    });
  }

  smokeData() {
    this.service.smokeView({ cartid: this.Edit }, environment.Token).subscribe((res) => {
      this.smokeList = res.data;
      this.formGroup.controls.normalstate.setValue(res.data.normalstate);
      this.formGroup.controls.troublestate.setValue(res.data.troublestate);
      this.formGroup.controls.noresponse.setValue(res.data.noresponse);
    });
  }

  thermalData() {
    this.service.thermalView({ cartid: this.Edit }, environment.Token).subscribe((res) => {
      this.thermalList = res.data;
      this.formGroup.controls.thershold = this.formBuilder.array([]);
      this.formGroup.controls.persistance = this.formBuilder.array([]);
      this.thermalList.forEach((o, i) => {
        this.thershold.push(new FormControl(o.thersholdvalue));
        this.persistance.push(new FormControl(o.persistance));
      });
    });
  }

  thermalTest(val) {
    this.service.thermalTest(val, environment.Token).subscribe((res) => {
      if (res.success == true) {
        this.termalsuccess = true;
        this.testtermalresult = res;
        $('#success').modal('show');
       
      }
      else {
        this.termalsuccess = false;
        $('#success').modal('show');
      }
    });
  }

  createForm() {
    let numericRegex = /^[0-9]+$/;
    this.formGroup = this.formBuilder.group({
      normalstate: ['', ''],
      troublestate: ['', ''],
      thershold: new FormArray([]),
      persistance: new FormArray([]),
      noresponse: ['', ''],
      status: [true, ''],
      type: ['', ''],
      tag: ['', ''],
      index: ['', [Validators.pattern(numericRegex)]],
    });
  }
  get thershold(): FormArray {
    return this.formGroup.get('thershold') as FormArray;
  }
  get persistance(): FormArray {
    return this.formGroup.get('persistance') as FormArray;
  }

  smokeUpdate() {
    var input = {
      id: this.smokeList.id,
      normalstate: this.formGroup.controls.normalstate.value,
      troublestate: this.formGroup.controls.troublestate.value,
      cartid: this.Edit,
      status: true,
      noresponse: this.formGroup.controls.noresponse.value
    }
    this.service.smokeUpdate(input, environment.Token).subscribe((res) => {
      this.alert = true;
      this.smokeData();
    });
  }

  thermalUpdate() {
    this.thermalList.forEach((o, i) => {
      this.thermalList[i].thersholdvalue = this.formGroup.controls.thershold.value[i];
      this.thermalList[i].persistance = this.formGroup.controls.persistance.value[i];
    });
    this.service.thermalUpdate(this.thermalList, environment.Token).subscribe((res) => {
      this.alert = true;
      this.thermalData();
    });
  }

  thermalAdd() {
    var input = {
      source: 'cart',
      sourceid: '',
      action: this.formGroup.controls.type.value == 'SPOT' ? 'IRTHGSpotTemperatureGet' : 'IRTHGAreaTemperatureGet',
      type: this.formGroup.controls.type.value,
      thermalindex: this.formGroup.controls.index.value,
      tagname: this.formGroup.controls.tag.value,
      thersholdvalue: 0,
      persistance: 0,
      cartid: this.Edit,
      status: true
    }
    if (this.formGroup.controls.tag.value && this.formGroup.controls.type.value && this.formGroup.controls.index.value) {
      this.service.thermalAdd(input, environment.Token).subscribe((res) => {
        if (res.success == true) {
          this.thermalerr = "";
          this.thermalData();
          $('#Add_sensor').modal('hide');
          this.formGroup.controls.type.setValue('');
          this.formGroup.controls.tag.setValue('');
          this.formGroup.controls.index.setValue('');
          this.formGroup.controls.type.markAsUntouched();
          this.formGroup.controls.tag.markAsUntouched();
          this.formGroup.controls.index.markAsUntouched();
        }else{
         // this.thermalerr = false;
          this.thermalerr = res.message;
        }
      });
    } else {
      this.formGroup.controls.type.markAsTouched();
      this.formGroup.controls.tag.markAsTouched();
      this.formGroup.controls.index.markAsTouched();
    }
  }

  thermalDelete() {
    this.service.thermalDelete({ id: this.deleteid  }, environment.Token).subscribe((res) => {
      this.thermalData();
      $('#delete').modal('hide');
    });
  }

  deletepopup(val)
  {
    this.deleteid = val;
    $('#delete').modal('show');
  }

  TabClick(tab) {
    $('.cus-tab').removeClass('selected');
    $('#' + tab).addClass('selected');
    $('.card-body').removeClass('selected');
    $('.' + tab + '-body').addClass("selected");
  }

  close()
  {
    this.alert = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SiteService } from 'src/app/service/site.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from '../../common.service';
import { async } from 'q';

@Component({
  selector: 'app-site-index',
  templateUrl: './site-index.component.html',
  styleUrls: ['./site-index.component.scss']
})
export class SiteIndexComponent implements OnInit {
  srhNam = new FormControl('');
  srhAdd = new FormControl('');
  srhStat = new FormControl('');
  DataList: any = [];
  pageno = 1;
  to: any;
  from: number;
  type: any = "Descending";
  input = {
    token: environment.Token,
    name: '',
    address: '',
    status: null,
    limit: 10,
    offset: 0,
    totalcount: '',
    order: "",
    title: ""
  };
  formGroup: FormGroup;
  constructor(private router: Router, private service: SiteService, private formBuilder: FormBuilder, private Commonservice: CommonService) { }


  sortTable(element) {
    element.firstElementChild.classList.remove("fa-sort")
    if (this.type == "Ascending") {
      this.type = "Descending";
      element.firstElementChild.classList.add("fa-sort-alpha-down-alt")
    } else {
      this.type = "Ascending"
      element.firstElementChild.classList.add("fa-sort-alpha-up")
    }
    this.input.title = element.getAttribute("data-field");
    this.input.order = this.type;
    this.getTheRecord()
  }
  ngOnInit() {
    const table = document.querySelector('table');
    table.querySelectorAll('th.sort')
      .forEach((element) => {
        element.addEventListener('click', event => {
          table.querySelectorAll('th.sort')
            .forEach((elements) => {
              elements.firstElementChild.classList.remove("fa-sort-alpha-down-alt", "fa-sort-alpha-up")
              elements.firstElementChild.classList.add("fa-sort")
            });
          this.sortTable(element);
        });
      })
    var Url = this.Commonservice.HistoryUrl[this.Commonservice.HistoryUrl.length - 2];
    if (Url && this.Commonservice.getInput()) {
      if (Url.split("?")[0] == "/site/edit") {
        this.input = this.Commonservice.getInput();
        this.pageno = this.input.offset + 1;
      }
    }
    this.getTheRecord();
    this.SearchForm();
  }

  SearchForm() {
    this.formGroup = this.formBuilder.group({
      name: [this.input.name, ''],
      address: [this.input.address, ''],
      status: [this.input.status, ''],
    });
    this.formGroup.controls.name.valueChanges.subscribe(val => {
      this.input.name = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.address.valueChanges.subscribe(val => {
      this.input.address = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.status.valueChanges.subscribe(val => {
      this.input.status = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
  }

  ngAfterViewInit() { }

  getTheRecord() {
    this.service.siteIndex(this.input, environment.Token).subscribe((res) => {
      this.DataList = res;
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].sno = this.input.limit * this.pageno + i + 1 - this.input.limit
      }
      this.to = this.pageno * this.input.limit - this.input.limit + res.data.length;
      this.from = this.pageno * this.input.limit - this.input.limit + res.data.length != 0 ? 1 : 0;
      this.input.totalcount = res.totalcount;
    })
  }

  current(val) {
    this.pageno = val;
    this.input.offset = this.pageno - 1;
    this.getTheRecord();
  }

  PerPage(val) {
    this.input.limit = val;
    this.pageno = 1;
    this.input.offset = this.pageno - 1;
    this.getTheRecord();
  }

  EditSite(val) {
    this.Commonservice.setInput(this.input);
    this.router.navigate(['/site/edit'], { queryParams: { Id: val } });
  }

  reset() {
    this.input.name = '';
    this.input.address = '';
    this.input.status = '';
    this.formGroup.patchValue({
      name: '',
      address: '',
      status: '',
    });
    this.getTheRecord();
  }
} 

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

const httpOptions = {
  headers : new HttpHeaders({'Content-Type':'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  URL: any;
  constructor(private http : HttpClient) { this.URL = environment.ApiUrl }

  Login(input: any): Observable<any> { 
    return this.http.post(environment.BaseUrl+'/authenticate',input ,httpOptions)
  }

  LoginToken(input: any): Observable<any> { 
    return this.http.post(this.URL+'/login',input ,httpOptions)
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment.prod';
declare var $: any;

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  formGroup: FormGroup;
  Edit: any;
  Passhide='password';
  confirmPasshide='password';
  siteList=[];
  Role=environment.Role
  invalid: string;
  alert: boolean = false;
  message: any;
  constructor(private router: Router, private service: UserService, private route: ActivatedRoute,private formBuilder: FormBuilder,) { 
    this.route.queryParams.subscribe(
      params => {
        this.Edit =  params['Id']; 
    });
  }
 
  ngOnInit() {
    this.createForm();
    if(this.Edit){
      this.AssignEdit();
    }
  }

  ngAfterViewInit() { 
    var _this=this;
    this.select();
    $('[data-toggle="switch"]').bootstrapSwitch('state', true);
    $('[data-toggle="switch"]').bootstrapSwitch('onColor', 'primary');
    $('[data-toggle="switch"]').bootstrapSwitch('onText', 'Active');
    $('[data-toggle="switch"]').on('switchChange.bootstrapSwitch', function (event, state) {
      _this.formGroup.controls.status.setValue(state);
    });

    $('#role').change(function () {
      $('#role').next().removeClass("is-invalid"); $('#role').next().css("background-position", "");
      _this.formGroup.get('role').markAsUntouched();
    });

    $('#site').change(function () {
      if($("#site option:selected").length == 0){ 
        $('#site').next().addClass("is-invalid"); $('#site').next().css("background-position", "center right calc(.375em + 1.1875rem)");
        _this.formGroup.get('site').markAsTouched();
      } else {
        $('#site').next().removeClass("is-invalid"); $('#site').next().css("background-position", "");
        _this.formGroup.get('site').markAsUntouched();
      }
    });
  }
  
  ValidMobile(val){ 
    if(val.length==10){
      var cleaned = ('' + this.formGroup.controls.mobile.value).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        this.formGroup.controls.mobile.setValue(match[1] + '-' + match[2] + '-' + match[3]);
      }
    }
  }

  Validname(val){ 
    this.formGroup.controls.name.setValue(val.replace(/\s/g, ""));
  }

  select(){ 
    var _this=this;
    setTimeout(function(){ 
      $("#site").multiselect('destroy');
      $('#site').multiselect({ includeSelectAllOption: true, });
      $("#role").multiselect('destroy');
      $('#role').multiselect({ includeSelectAllOption: true, });
      if(!_this.Edit){ 
        var siteOptions=[];
        _this.service.SiteList(localStorage.getItem('token')).subscribe((res)=>{
          res.data.forEach((o, i) => {
            var ordersData = {
              label: o.name,
              value: o.id,
              selected: false,
            };
            siteOptions.push(ordersData);
          });
          $('#site').multiselect('dataprovider', siteOptions);
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("input-group");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("form-control");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("btn");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("dropdown-toggle");
          $('button.multiselect.dropdown-toggle.btn.btn-default').parent().css("padding-right", "0.5rem");
        });
      }
    }, 100); 
  }

  createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let numericRegex = "[0-9]{3}-[0-9]{3}-[0-9]{4}";
    this.formGroup = this.formBuilder.group({
      name : ['', Validators.required], 
      EmailId : ['', [Validators.required, Validators.pattern(emailregex)]],
      mobile: ['', [Validators.required, Validators.pattern(numericRegex)]],
      password : ['',[Validators.required]], 
      confirmpass: ['', Validators.required],  
      role : ['', ''], 
      site : ['', ''], 
      status: [true, [Validators.required]],   
      Fname: ['', [Validators.required]],   
      Lname: ['', [Validators.required]],   
    },
    { 
      validators: this.passwordMatch
    }); 
  }

  passwordMatch(formGroup: FormGroup) {
    let password = formGroup.controls.password.value;
    let confirmPassword = formGroup.controls.confirmpass.value;
    if(password && confirmPassword) {
      return password === confirmPassword ? null : { notSame: true }
    }   
  }

  capFstLtr(e) {
    var string = this.formGroup.get(e).value;
    this.formGroup.get(e).setValue((string.charAt(0).toUpperCase() + string.slice(1)).replaceAll(/\s/g,''));
  }

  AssignEdit(){
    this.formGroup.controls.password.clearValidators();
    this.formGroup.controls.confirmpass.clearValidators();
    this.formGroup.controls.password.updateValueAndValidity();
    this.formGroup.controls.confirmpass.updateValueAndValidity();
    this.formGroup.controls.name.disable();
    var input = {
      user_id: this.Edit,
    }
    this.service.ViewUser(input,environment.Token).subscribe((res)=>{
      this.formGroup.controls.name.patchValue(res.data.name);
      this.formGroup.controls.EmailId.patchValue(res.data.email);
      this.formGroup.controls.mobile.patchValue(res.data.phone_number);
      this.formGroup.controls.password.patchValue(res.data.password);
      this.formGroup.controls.Fname.patchValue(res.data.firstname);
      this.formGroup.controls.Lname.patchValue(res.data.lastname);
      this.formGroup.controls.status.patchValue(res.data.status);
      $('[data-toggle="switch"]').bootstrapSwitch('state', res.data.status);
      var roleOptions = [
        {label: 'User', value: '2', selected: res.data.role_id==2?true:false},
        {label: 'SFAM', value: '1', selected: res.data.role_id==1?true:false},
      ];
      var siteOptions = [];
      this.service.SiteList(localStorage.getItem('token')).subscribe((val)=>{
        val.data.forEach((o, i) => {
          var ordersData = {
            label: o.name,
            value: o.id,
            selected: false,
          };
          res.data.site_id.forEach((s) => {
            if (s == o.id) { 
              ordersData.label = o.name;
              ordersData.value = o.id;
              ordersData.selected = true;
            }
          });
          siteOptions.push(ordersData);
        });
        $('#site').multiselect('dataprovider', siteOptions);
        $('#role').multiselect('dataprovider', roleOptions);
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("input-group");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("form-control");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("btn");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().addClass("dropdown-toggle");
        $('button.multiselect.dropdown-toggle.btn.btn-default').parent().css("padding-right", "0.5rem");
      });
    });
  }  

  Create(){
    var _this = this;
    var input ={
      username: this.formGroup.controls.name.value,
      email: this.formGroup.controls.EmailId.value,
      mobile: this.formGroup.controls.mobile.value,
      password: this.formGroup.controls.password.value,
      roleid: $('select#role').val(),
      site: [],
      active: this.formGroup.controls.status.value,
      firstname: this.formGroup.controls.Fname.value,
      lastname: this.formGroup.controls.Lname.value,
    }
    if(environment.Role==1){input.site=[""+environment.Site];input.roleid='2';}
    if(environment.Role!=1){
      if($("#site option:selected").length == 0){ 
        $('#site').next().addClass("is-invalid"); $('#site').next().css("background-position", "center right calc(.375em + 1.1875rem)");
        _this.formGroup.get('site').markAsTouched();
      } else {
        $("#site option:selected").each(function () {
          input.site.push($(this).val());
        });
        $('#site').next().removeClass("is-invalid"); 
        _this.formGroup.get('site').markAsUntouched();
      }
    }
    if(!input.roleid){
      $('#role').next().addClass("is-invalid"); 
      $('#role').next().css("background-position", "center right calc(.375em + 1.1875rem)");
      _this.formGroup.controls.role.markAsTouched();
    }
    this.formGroup.controls.name.markAsTouched();
    this.formGroup.controls.EmailId.markAsTouched();
    this.formGroup.controls.mobile.markAsTouched();
    this.formGroup.controls.password.markAsTouched();
    this.formGroup.controls.confirmpass.markAsTouched();
    this.formGroup.controls.Fname.markAsTouched();
    this.formGroup.controls.Lname.markAsTouched();
    input.roleid= Number(input.roleid);
    if(this.formGroup.valid&& environment.Role==1||this.formGroup.valid && this.formGroup.controls['site'].untouched && this.formGroup.controls['role'].untouched){
      this.service.AddUser(input,environment.Token).subscribe((res)=>{
        if(res.success==true){ 
          
          this.alert = true;
          this.message = "User created successfully!"
          //this.router.navigate(['/user/index']);
         }
        if(res.message=="Email is already exist"){ this.invalid="Email already exists"; }
        if(res.message=="Username is already exist"){ this.invalid="Username already exists"; }
      });
    }
  }

  Update(){
    var _this = this;
    var input ={
      id: this.Edit,
      username: this.formGroup.controls.name.value,
      email: this.formGroup.controls.EmailId.value,
      mobile: this.formGroup.controls.mobile.value,
      password: this.formGroup.controls.password.value,
      roleid: $('select#role').val(),
      site: [],
      active: this.formGroup.controls.status.value,
      firstname: this.formGroup.controls.Fname.value,
      lastname: this.formGroup.controls.Lname.value,
    }
    if(environment.Role==1){input.site=[""+environment.Site];input.roleid=2;}
    if(environment.Role!=1){
      if($("#site option:selected").length == 0){ 
      $('#site').next().addClass("is-invalid"); $('#site').next().css("background-position", "center right calc(.375em + 1.1875rem)");
      _this.formGroup.get('site').markAsTouched();
      } else {
        $("#site option:selected").each(function () {
          input.site.push($(this).val());
        });
        $('#site').next().removeClass("is-invalid"); 
        _this.formGroup.get('site').markAsUntouched();
      }
    }
    if(!input.roleid){
      $('#role').next().addClass("is-invalid"); 
      $('#role').next().css("background-position", "center right calc(.375em + 1.1875rem)");
      _this.formGroup.controls.role.markAsTouched();
    }
    // this.formGroup.controls.name.markAsTouched();
    this.formGroup.controls.EmailId.markAsTouched();
    this.formGroup.controls.mobile.markAsTouched();
    this.formGroup.controls.password.markAsTouched();
    this.formGroup.controls.confirmpass.markAsTouched();
    this.formGroup.controls.Fname.markAsTouched();
    this.formGroup.controls.Lname.markAsTouched();
    if(this.formGroup.controls.password.value){
      this.formGroup.controls.confirmpass.setValidators([Validators.required]);
      this.formGroup.controls.confirmpass.updateValueAndValidity();
    }
    input.roleid= Number(input.roleid);
    if(this.formGroup.valid&& environment.Role==1||this.formGroup.valid && this.formGroup.controls['site'].untouched && this.formGroup.controls['role'].untouched){
      this.service.UpdateUser(input,environment.Token).subscribe((res)=>{
        if(res.success==true){ 
          //this.router.navigate(['/user/index']); 
          this.alert = true;
          this.message = "User updated successfully!"
        
        }
        if(res.message=="Email is already exist"){ this.invalid="Email is already exist"; }
        if(res.message=="Username is already exist"){ this.invalid="Username is already exist"; }
      });
    }
  }

  cloce()
  {
    this.alert = false;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  URL = environment.ApiUrl;
  constructor(private http: HttpClient) { }

  siteIndex(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/siteindex', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  AddSite(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/addsite', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  ViewSite(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/siteview', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }

  UpdateSite(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/editsite', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorService } from 'src/app/service/sensor.service';
import { environment } from 'src/environments/environment.prod';
declare var $: any;

@Component({
  selector: 'app-transaction-view',
  templateUrl: './transaction-view.component.html',
  styleUrls: ['./transaction-view.component.scss']
})
export class TransactionViewComponent implements OnInit {
  formGroup: FormGroup;
  Tranid: any;
  Cartid: any;
  thermalList: any;
  smokeList: any;
  Edit: any;

  alert: boolean = false;
  message: any;
  constructor(private router: Router, private route: ActivatedRoute, private service: SensorService, private formBuilder: FormBuilder) {
    this.route.queryParams.subscribe(params => {
      this.Tranid = params['Id'];
      this.Cartid = params['cart'];
      this.Edit = params['cart'];
    });
  }

  ngOnInit() {
    this.createForm();
    this.thermalData();
    this.smokeData();
  }

  smokeData() {
    this.service.smoketView({ cartid: this.Edit, sourceid: this.Tranid }, environment.Token).subscribe((res) => {

      this.smokeList = res.data;
      if (this.smokeList) {
        this.formGroup.controls.normalstate.setValue(res.data.normalstate);
        this.formGroup.controls.troublestate.setValue(res.data.troublestate);
        this.formGroup.controls.noresponse.setValue(res.data.noresponse);
      }

    });
  }

  smokeUpdate() {

    if (this.smokeList.source == "cart") {
      // var input = {
      //   sourceid: this.smokeList.data[0].sourceid,
      //   normalstate: this.formGroup.controls.normalstate.value,
      //   troublestate: this.formGroup.controls.troublestate.value,
      //   cartid: this.Edit,
      //   status: true,
      //   noresponse: this.formGroup.controls.noresponse.value
      // }
      // this.service.smoketUpdate(input, environment.Token).subscribe((res) => {
      //   this.alert = true;
      //   this.message = res.message;
      //   this.smokeData();
      // });
    } else {
      var inputs = {
        id: this.smokeList.id,
        sourceid: this.smokeList.sourceid,
        normalstate: this.formGroup.controls.normalstate.value,
        troublestate: this.formGroup.controls.troublestate.value,
        cartid: this.Edit,
        status: true,
        noresponse: this.formGroup.controls.noresponse.value
      }
      this.service.smoketEdit(inputs, environment.Token).subscribe((res) => {
        this.alert = true;
        this.message = res.message;
        this.smokeData();
      });
    }
  }


  thermalData() {
    this.service.thermalTranView({ cartid: this.Cartid, sourceid: this.Tranid }, environment.Token).subscribe((res) => {
      this.thermalList = res.data;
      this.thermalList.forEach((o, i) => {
        this.thershold.push(new FormControl(o.thersholdvalue));
        this.persistance.push(new FormControl(o.persistance));
      });
    });
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      normalstate: ['', ''],
      troublestate: ['', ''],
      thershold: new FormArray([]),
      persistance: new FormArray([]),
      status: [true, ''],
      noresponse: ['', ''],
      type: ['', ''],
      tag: ['', ''],
    });
  }
  get thershold(): FormArray {
    return this.formGroup.get('thershold') as FormArray;
  }
  get persistance(): FormArray {
    return this.formGroup.get('persistance') as FormArray;
  }

  thermalUpdate() {
    this.thermalList.forEach((o, i) => {
      this.thermalList[i].thersholdvalue = this.formGroup.controls.thershold.value[i];
      this.thermalList[i].persistance = this.formGroup.controls.persistance.value[i];
    });
    if (this.thermalList[0].sourceid == null || !this.thermalList[0].sourceid) {
      this.thermalList.forEach((o, i) => {
        this.thermalList[i].sourceid = this.Tranid;
        this.thermalList[i].source = 'transaction';
      });
      this.service.thermalTranAdd(this.thermalList, environment.Token).subscribe((res) => {
        if (res.success == true) {
          this.alert = true;
          this.message = "Thermal created successfully!"
          // this.router.navigate(['/transaction/index']); 

        }
      });
    }
    else {
      this.service.thermalTranUpdate(this.thermalList, environment.Token).subscribe((res) => {
        if (res.success == true) {
          this.alert = true;
          this.message = "Thermal updated successfully!"
          //this.router.navigate(['/transaction/index']); 
        }
      });
    }
  }

  cloce() {
    this.alert = false;
  }

  TabClick(tab) {
    this.alert = false;
    $('.cus-tab').removeClass('selected');
    $('#' + tab).addClass('selected');
    $('.card-body').removeClass('selected');
    $('.' + tab + '-body').addClass("selected");
  }

}

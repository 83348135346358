import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TransactionService } from 'src/app/service/transaction.service';
import { HomeService } from 'src/app/service/home.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from '../../common.service';
declare var $: any;

@Component({
  selector: 'app-transaction-index',
  templateUrl: './transaction-index.component.html',
  styleUrls: ['./transaction-index.component.scss']
})
export class TransactionIndexComponent implements OnInit {

  srhSite = new FormControl('');
  srhCart = new FormControl('');
  srhTran = new FormControl('');
  Role = environment.Role;
  DataList: any = [];
  pageno = 1;
  to: any;
  from: number;
  type: any = "Descending";
  input = {
    token: environment.Token,
    site_id: environment.Role == 1 || environment.Role == -1 ? environment.Site : '',
    sitename: '',
    cartname: '',
    locationname:'',
    locatoin_id: '',
    transactionName: '',
    transaction_fromdate: '',
    transaction_toDate: '',
    status: '',
    limit: 10,
    offset: 0,
    totalcount: '',
    order: "",
    title: ""
  };
  usrList = [];
  item: any;
  formGroup: FormGroup;
  constructor(private router: Router, private service: TransactionService, private formBuilder: FormBuilder, private Commonservice: CommonService,private homeService: HomeService) { }

  sortTable(element) {
    element.firstElementChild.classList.remove("fa-sort")
    if (this.type == "Ascending") {
      this.type = "Descending";
      element.firstElementChild.classList.add("fa-sort-alpha-down-alt")
    } else {
      this.type = "Ascending"
      element.firstElementChild.classList.add("fa-sort-alpha-up")
    }
    this.input.title = element.getAttribute("data-field");
    this.input.order = this.type;
    this.getTheRecord()
  }

  ngOnInit() {

    const table = document.querySelector('table');
    table.querySelectorAll('th.sort')
      .forEach((element) => {
        element.addEventListener('click', event => {
          table.querySelectorAll('th.sort')
            .forEach((elements) => {
              elements.firstElementChild.classList.remove("fa-sort-alpha-down-alt", "fa-sort-alpha-up")
              elements.firstElementChild.classList.add("fa-sort")
            });
          this.sortTable(element);
        });
      })

    var Url = this.Commonservice.HistoryUrl[this.Commonservice.HistoryUrl.length - 2];
    if (Url && this.Commonservice.getInput()) {
      if (Url.split("?")[0] == "/transaction/edit") {
        this.input = this.Commonservice.getInput();
        this.pageno = this.input.offset + 1;
      }
    }
    this.getTheRecord();
    this.SearchForm();
  }

  SearchForm() {
    this.formGroup = this.formBuilder.group({
      site: [this.input.sitename, ''],
      locationname: [this.input.locationname, ''],
      cart: [this.input.cartname, ''],
      tran: [this.input.transactionName, ''],
      status: [this.input.status, ''],
    });
    if (this.Role == 0) {
      this.formGroup.controls.site.valueChanges.subscribe(val => {
        this.input.sitename = val;
        this.input.offset = 0;
        this.pageno = 1;
        this.getTheRecord();
      });
    }
    this.formGroup.controls.cart.valueChanges.subscribe(val => {
      this.input.cartname = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.tran.valueChanges.subscribe(val => {
      this.input.transactionName = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.status.valueChanges.subscribe(val => {
      this.input.status = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
    this.formGroup.controls.locationname.valueChanges.subscribe(val => {
      this.input.locationname = val;
      this.input.offset = 0;
      this.pageno = 1;
      this.getTheRecord();
    });
  }

  Notification() {
    var input = {
      transaction_id: "" + this.item.id,
      site_id: environment.Role == 1 || environment.Role == -1 ? "" + environment.Site : this.item.site_id,
      user_id: [],
      notification_id: [],
      status: true
    }
    this.usrList.forEach((u, i) => {
      if ($('#usr' + u.id + ':checked').val()) { input.user_id.push($('#usr' + u.id + ':checked').val()); }
      if ($('#noti' + u.id + ':checked').val()) { input.notification_id.push($('#noti' + u.id + ':checked').val()); }
    });
    // if(input.notification_id.length==0){input.notification_id=[""]}
    // if(input.user_id.length==0){input.user_id=[""]}
    this.service.NotiAdd(input, environment.Token).subscribe((res) => {
      $('#kt_modal_t1').modal('hide');
    });
  }

  Assign(val) {
    var input = {
      id: val.id,
    }
    this.item = val;
    var site_id = environment.Role == 1 || environment.Role == -1 ? "" + environment.Site : this.item.site_id;
    this.service.UserList({ site_id: site_id }, environment.Token).subscribe((val) => {
      this.usrList = val.data;
      this.service.TransView(input, environment.Token).subscribe((res) => {
        res.data.user_id.forEach((u, i) => {
          $('#usr' + u).prop("checked", true);
          $('#noti' + u).prop("disabled", false);
        });
        res.data.notification_id.forEach((u, i) => { $('#noti' + u).prop("checked", true); });
        $('#kt_modal_t1').modal('show');
      });
    });
  }

  dis(e) {
    if (e.target.checked) { $('#noti' + e.target.value).prop("disabled", false);$('#noti' + e.target.value).prop("checked", true); }
    else { $('#noti' + e.target.value).prop("disabled", true); $('#noti' + e.target.value).prop("checked", false); }
  }

  DeleteItem(val) {
    this.item = val;
    $('#Trans_delete').modal('show');
  }

  DeleteTran() {
    var input = {
      id: this.item,
      isdeleted: 1
    }
    this.service.TransDelete(input, environment.Token).subscribe((res) => {
      this.getTheRecord();
      $('#Trans_delete').modal('hide');
    });
    $('#Trans_delete').modal('hide');
  }

  EditTran(val,status) {
    this.Commonservice.setInput(this.input);
    this.router.navigate(['/transaction/edit'], { queryParams: { Id: val,status:status } });
  }
  Sensor(val) {
    this.Commonservice.setInput(this.input);
    this.router.navigate(['/transaction/sensor'], { queryParams: { Id: val.id, cart: val.cartid } });
  }

  getTheRecord() {
    this.service.TransactionIndex(this.input, environment.Token).subscribe((res) => {
      this.DataList = res;
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].sno = this.input.limit * this.pageno + i + 1 - this.input.limit
      }
      this.to = this.pageno * this.input.limit - this.input.limit + res.data.length;
      this.from = this.pageno * this.input.limit - this.input.limit + res.data.length != 0 ? 1 : 0;
      this.input.totalcount = res.totalcount;
    })
  }

  current(val) {
    this.pageno = val;
    this.input.offset = this.pageno - 1;
    this.getTheRecord();
  }

  PerPage(val) {
    this.input.limit = val;
    this.pageno = 1;
    this.input.offset = this.pageno - 1;
    this.getTheRecord();
  }

  reset() {
    this.input.locatoin_id = '';
    this.input.locationname = '';
    this.input.cartname = '';
    this.input.sitename = '';
    this.input.transactionName = '';
    this.input.status = '';
    this.formGroup.patchValue({
      site: '',
      cart: '',
      tran: '',
      status: '',
      locationname: '',
    });
    this.getTheRecord();
  }

  dateconvert(datevalue) {
    if (datevalue) {
      return this.homeService.dateconvert(datevalue)
    } else {
      return "";
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/service/cart.service';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from '../../common.service';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-cart-create',
  templateUrl: './cart-create.component.html',
  styleUrls: ['./cart-create.component.scss']
})
export class CartCreateComponent implements OnInit {

  formGroup: FormGroup;
  Edit: any;
  siteList: any;
  invalidDate: boolean;
  invalid: string;
  transaction={
    shifted: '',
    name: '',
    status: '',
  };
  alert: boolean = false;
  message: any;
  constructor(private datepipe: DatePipe,private route: ActivatedRoute,private Commonservice: CommonService,private Userservice: UserService,private router: Router,private formBuilder: FormBuilder, private service: CartService) { 
    this.route.queryParams.subscribe(
      params => {
        this.Edit =  params['Id']; 
    });
    this.Userservice.SiteList(localStorage.getItem('token')).subscribe((res) => {
      this.siteList=res.data;
    });
  }

  ngOnInit() {
    this.createForm();
    if(this.Edit){ this.AssignEdit(); }
    this.formGroup.get('cart').valueChanges.subscribe(value => {
      this.invalid = "";
    });
    this.formGroup.get('camId').valueChanges.subscribe(value => {
      this.invalid = "";
    });
  }
  ngAfterViewInit() { 
    var _this=this;
    if(!this.Edit){this.InitDate(false);}
    $('[data-toggle="switch"]').bootstrapSwitch('state', true);
    $('[data-toggle="switch"]').bootstrapSwitch('onColor', 'primary');
    $('[data-toggle="switch"]').bootstrapSwitch('onText', 'Available');
    $('[data-toggle="switch"]').on('switchChange.bootstrapSwitch', function (event, state) {
      _this.formGroup.controls.status.setValue(state);
    });
  }
  
  InitDate(transaction) {
    var _this=this;
    $('#cart_Sdate').datepicker({ startDate: new Date(), orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#cart_Edate').datepicker({ startDate: new Date(), orientation: "left bottom", todayHighlight: true, autoclose: true });
    $('#cart_Sdate').change(function () {
      _this.formGroup.controls.Sdate.patchValue($('#cart_Sdate').val());
      _this.checkDates();
    });
    $('#cart_Edate').change(function () {
      _this.formGroup.controls.Edate.patchValue($('#cart_Edate').val());
      _this.checkDates();
    });
    if(new Date() <= new Date(this.formGroup.controls.Edate.value)){
      $('#cart_Edate').datepicker("setDate", new Date(this.formGroup.controls.Edate.value));
    }
    if(new Date() <= new Date(this.formGroup.controls.Sdate.value)){
      $('#cart_Sdate').datepicker("setDate", new Date(this.formGroup.controls.Sdate.value));
    }
    if(new Date() <= new Date(this.formGroup.controls.Edate.value)){
      if(transaction==true){ 
        $('#shiftTo').attr("disabled", true);
        this.formGroup.controls.Sdate.disable(); 
        this.formGroup.controls.Edate.disable(); 
      }
    } 
  }

  checkDates() {
    let fromDate = this.Commonservice.ServerDate(this.formGroup.controls.Sdate.value);
    let toDate = this.Commonservice.ServerDate(this.formGroup.controls.Edate.value);
    this.invalidDate=false
    if(toDate && fromDate){
      if(new Date(fromDate) > new Date(toDate)){
        this.invalidDate=true
      }
    }
  }

  createForm(){
    let Ip: RegExp = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}\:[0-9]{1,4}$/;
    this.formGroup = this.formBuilder.group({
      site : ['', Validators.required], 
      cart : ['', Validators.required],  
      waveIp : ['', [Validators.required]],  
      flirIp : ['', [Validators.required]],  
      shiftTo : ['', ''],  
      Sdate : ['', ''],  
      Edate : ['', ''],  
      status: [true, [Validators.required]],   
      camId : ['', [Validators.required]],  
    });
  }
  
  AssignEdit() {
    var input = {
      id: this.Edit,
    }
    this.service.CartView(input,environment.Token).subscribe((res) => {
      this.formGroup.controls.site.patchValue(res.data.site_id);
      this.formGroup.controls.cart.patchValue(res.data.cartname);
      this.formGroup.controls.waveIp.patchValue(res.data.wave_ip);
      this.formGroup.controls.flirIp.patchValue(res.data.flir_ip);
      this.formGroup.controls.camId.patchValue(res.data.camId);
      this.formGroup.controls.status.patchValue(res.data.status);
      $('[data-toggle="switch"]').bootstrapSwitch('state', res.data.status);
      this.transaction.status=res.data.transaction;
      this.transaction.name=res.data.transaction_name;
      this.transaction.shifted=res.data.shifted_site_id;
      if(res.data.shifted_site_id||res.data.transaction){
        this.formGroup.controls.shiftTo.patchValue(res.data.shifted_site_id);
        $('#shiftTo').attr("disabled", true); 

        // this.formGroup.controls.shiftTo.patchValue(res.data.shifted_site_id);
        // if(new Date() > new Date(res.data.shifted_from_date)){
        //   var s = (this.Commonservice.Datepick(res.data.shifted_from_date)).split("/");
        //   var Sd=s[1]+"/"+s[2]+"/"+s[0];
        //   this.formGroup.controls.Sdate.patchValue(Sd);
        // }
        // else { this.formGroup.controls.Sdate.patchValue(this.Commonservice.Datepick(res.data.shifted_from_date)); }
        // if(new Date() > new Date(res.data.shifted_to_date)){
        //   var s = (this.Commonservice.Datepick(res.data.shifted_to_date)).split("/");
        //   var Sd=s[1]+"/"+s[2]+"/"+s[0];
        //   this.formGroup.controls.Edate.patchValue(Sd);
        // }
        // else { this.formGroup.controls.Edate.patchValue(this.Commonservice.Datepick(res.data.shifted_to_date)); }
      }
      // this.InitDate(res.data.transaction);
      $('#site').attr("disabled", true); 
    });
  }

  Submit() {
    var input = {
      site_id: this.formGroup.controls.site.value,
      cartname: this.formGroup.controls.cart.value,
      waveIpaddress: this.formGroup.controls.waveIp.value,
      flirIpaddress: this.formGroup.controls.flirIp.value,
      status: this.formGroup.controls.status.value,
      is_shifted: 0,
      shiftedSiteId: '',
      shiftedFromDate: '',
      shiftedToDate: '',
      camId: this.formGroup.controls.camId.value,
    }
    if(this.formGroup.invalid) {
      this.formGroup.controls.site.markAsTouched();
      this.formGroup.controls.cart.markAsTouched();
      this.formGroup.controls.waveIp.markAsTouched();
      this.formGroup.controls.flirIp.markAsTouched();
    }
    if(this.formGroup.valid && !this.invalidDate){
      this.service.CartAdd(input,environment.Token).subscribe((res) => {
        if (res.message == "Success") { 
          this.alert = true;
          this.message = "Cart created successfully!";
          //this.router.navigate(['/cart/index']); 
        }
        if (res.message == "Cartname is already exists") { this.invalid='Cart name already exists'; }
        if (res.message == "CamId is already exists") { this.invalid='CameraID already exists'; }
      });
    }
  }

  Update(){
    var input = {
      id: this.Edit,
      site_id: this.formGroup.controls.site.value,
      cartname: this.formGroup.controls.cart.value,
      waveIpaddress: this.formGroup.controls.waveIp.value,
      flirIpaddress: this.formGroup.controls.flirIp.value,
      status: this.formGroup.controls.status.value,
      is_shifted: this.formGroup.controls.shiftTo.value?1:0,
      shiftedSiteId: this.formGroup.controls.shiftTo.value,
      shiftedFromDate: '',
      shiftedToDate: '',
      camId: this.formGroup.controls.camId.value,
    }
    if($('#Bring').prop("checked")==true){ input.is_shifted=0 }
    input.shiftedSiteId=input.shiftedSiteId?input.shiftedSiteId:"";
    // if(this.formGroup.controls.shiftTo.value) {
    //   input.shiftedFromDate=this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    //   this.formGroup.controls.Sdate.setValidators([Validators.required]);
    //   this.formGroup.controls.Edate.setValidators([Validators.required]);
    //   this.formGroup.controls.Sdate.updateValueAndValidity();
    //   this.formGroup.controls.Edate.updateValueAndValidity();
    //   this.formGroup.controls.Edate.markAsTouched();
    //   this.formGroup.controls.Sdate.markAsTouched();
    //   input.shiftedFromDate=this.Commonservice.ServerDate(this.formGroup.controls.Sdate.value);
    //   input.shiftedToDate=this.Commonservice.ServerDate(this.formGroup.controls.Edate.value);
    // }  else{
    //   this.formGroup.controls.Sdate.clearValidators();
    //   this.formGroup.controls.Edate.clearValidators();
    //   this.formGroup.controls.Sdate.updateValueAndValidity();
    //   this.formGroup.controls.Edate.updateValueAndValidity();
    // }
    if(this.formGroup.invalid) {
      this.formGroup.controls.site.markAsTouched();
      this.formGroup.controls.cart.markAsTouched();
      this.formGroup.controls.waveIp.markAsTouched();
      this.formGroup.controls.flirIp.markAsTouched();
    }
    if(this.formGroup.valid){

      if(this.transaction.status)
      {
        this.invalid='This cart is already engaged';
      }else
      {
        this.service.CartUpdate(input,environment.Token).subscribe((res) => {
          if (res.success==true) { 
            this.alert = true;
            this.message = "Cart updated successfully!";
            //  this.router.navigate(['/cart/index']);
           }
          if (res.message == "Cartname is already exist") { this.invalid='Cart name already exists'; }
          if (res.message == "CamId is already exist") { this.invalid='CameraID already exists'; }
        });
      }

    }
  }

  cloce()
  {
    this.alert = false;
  }
}

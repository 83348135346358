import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'src/app/service/location.service';
import { environment } from 'src/environments/environment.prod';
declare var $: any;

@Component({
  selector: 'app-location-create',
  templateUrl: './location-create.component.html',
  styleUrls: ['./location-create.component.scss']
})
export class LocationCreateComponent implements OnInit {
  formGroup: FormGroup;
  Edit: any;
  alert: boolean = false;
  message: any;
  constructor(private router: Router, private route: ActivatedRoute, private service: LocationService, private formBuilder: FormBuilder, ) {
    this.route.queryParams.subscribe(
      params => {
        this.Edit = params['Id'];
      });
  }

  ngOnInit() {
    this.createForm();
    if (this.Edit) {
      this.AssignEdit();
    }
  }
  ngAfterViewInit() {
    var _this = this;
    this.select();
    $('[data-toggle="switch"]').bootstrapSwitch('state', true);
    $('[data-toggle="switch"]').bootstrapSwitch('onColor', 'primary');
    $('[data-toggle="switch"]').bootstrapSwitch('onText', 'Active');
    $('[data-toggle="switch"]').on('switchChange.bootstrapSwitch', function (event, state) {
      _this.formGroup.controls.status.setValue(state);
    });
  }
  select() {

  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      locname: ['', Validators.required],
      description: ['', ''],
      status: [true, [Validators.required]],
    });
  }

  AssignEdit() {
    var input = {
      id: this.Edit,
    }
    this.service.ViewLocation(input, environment.Token).subscribe((res) => {
      this.formGroup.controls.locname.patchValue(res.data.name);
      this.formGroup.controls.description.patchValue(res.data.address);
      this.formGroup.controls.status.patchValue(res.data.status);
      $('[data-toggle="switch"]').bootstrapSwitch('state', res.data.status);
    });
  }
  Submit() {
    var input = {
      site_id: '',
      name: this.formGroup.controls.locname.value,
      address: this.formGroup.controls.description.value,
      status: this.formGroup.controls.status.value,
    }
    if (environment.Role == 1 || environment.Role == -1) { input.site_id = environment.Site; }
    if (this.formGroup.invalid) {
      this.formGroup.controls.locname.markAsTouched();
      this.formGroup.controls.description.markAsTouched();
    }
    else {
      this.service.AddLocation(input, environment.Token).subscribe((res) => {
        if (res.message == "Success") {
          this.alert = true;
          this.message = "Location created successfully!"
          //this.router.navigate(['/location/index']);
        }
      });
    }
  }

  Update() {
    var input = {
      id: this.Edit,
      site_id: '',
      name: this.formGroup.controls.locname.value,
      address: this.formGroup.controls.description.value,
      status: this.formGroup.controls.status.value,
    }
    if (environment.Role == 1 || environment.Role == -1) { input.site_id = environment.Site; }
    if (this.formGroup.invalid) {
      this.formGroup.controls.locname.markAsTouched();
      this.formGroup.controls.description.markAsTouched();
    } else {
      this.service.EditLocation(input, environment.Token).subscribe((res) => {
        if (res.message == "Success") {
          // this.router.navigate(['/location/index']); 
          this.alert = true;
          this.message = "Location updated successfully!"
        }
      });
    }
  }

  cloce()
  {
    this.alert = false;
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router){}
  canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(localStorage.getItem("token")){
      environment.Auth=localStorage.getItem("auth");
      environment.Token=localStorage.getItem("token");
      environment.Role=localStorage.getItem("role");
      environment.Site=localStorage.getItem("site");
      return true;
    } 
    else{ this.router.navigate(['/login']); return false; }
  }
}

@Injectable({
  providedIn: 'root'
})

export class SuperAGuard implements CanActivate {
  constructor(private router: Router){}
  canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(environment.Role==-1 || environment.Role==0){ return true; }else{  return false; }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SiteAGuard implements CanActivate {
  constructor(private router: Router){}
  canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(environment.Role==-1 || environment.Role==1){ return true; }else{  return false; }
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private router: Router){}
  canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(environment.Role==-1 || environment.Role==0 || environment.Role==1 || environment.Role==2){ return true; }else{  return false; }
  }
}

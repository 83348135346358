import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { AuthGuard, SiteAGuard, SuperAGuard, UserGuard } from './pages/auth.guard';
import { UserIndexComponent } from './pages/user-management/user-index/user-index.component';
import { UserCreateComponent } from './pages/user-management/user-create/user-create.component';
import { SiteIndexComponent } from './pages/site-management/site-index/site-index.component';
import { SiteCreateComponent } from './pages/site-management/site-create/site-create.component';
import { CartCreateComponent } from './pages/cart-management/cart-create/cart-create.component';
import { CartIndexComponent } from './pages/cart-management/cart-index/cart-index.component';
import { LocationIndexComponent } from './pages/location-management/location-index/location-index.component';
import { LocationCreateComponent } from './pages/location-management/location-create/location-create.component';
import { TransactionCreateComponent } from './pages/transaction/transaction-create/transaction-create.component';
import { TransactionIndexComponent } from './pages/transaction/transaction-index/transaction-index.component';
import { HistoryReportIndexComponent } from './pages/report/history-report/history-report-index/history-report-index.component';
import { HistoryReportViewComponent } from './pages/report/history-report/history-report-view/history-report-view.component';
import { LogIndexComponent } from './pages/report/log-report/log-index/log-index.component';
import { EmptyComponent } from './pages/layout/empty/empty.component';
import { CartSensorComponent } from './pages/cart-management/cart-sensor/cart-sensor.component';
import { TransactionViewComponent } from './pages/transaction/transaction-view/transaction-view.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
		path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: 
    [
      { path: '',component: HomeComponent }, 
      { path: 'home',component: HomeComponent }, 
      { path: 'user/index', canActivate: [UserGuard], component: UserIndexComponent }, 
      { path: 'user/create', canActivate: [UserGuard], component: UserCreateComponent }, 
      { path: 'user/edit', canActivate: [UserGuard], component: UserCreateComponent }, 
      { path: 'site/index', canActivate: [SuperAGuard], component: SiteIndexComponent }, 
      { path: 'site/create', canActivate: [SuperAGuard], component: SiteCreateComponent }, 
      { path: 'site/edit', canActivate: [SuperAGuard], component: SiteCreateComponent }, 
      { path: 'cart/create', canActivate: [SuperAGuard], component: CartCreateComponent }, 
      { path: 'cart/edit', canActivate: [SuperAGuard], component: CartCreateComponent },
      { path: 'cart/index', canActivate: [UserGuard], component: CartIndexComponent },
      { path: 'location/index', canActivate: [UserGuard], component: LocationIndexComponent },
      { path: 'location/create', canActivate: [UserGuard], component: LocationCreateComponent },
      { path: 'location/edit', canActivate: [UserGuard], component: LocationCreateComponent },
      { path: 'transaction/create', canActivate: [SiteAGuard], component: TransactionCreateComponent },
      { path: 'transaction/edit', canActivate: [SiteAGuard], component: TransactionCreateComponent },
      { path: 'transaction/index', canActivate: [UserGuard], component: TransactionIndexComponent },  
      { path: 'log/index', canActivate: [UserGuard], component: LogIndexComponent }, 
      { path: 'history/index', canActivate: [UserGuard], component: HistoryReportIndexComponent }, 
      { path: 'history/view', canActivate: [UserGuard], component: HistoryReportViewComponent }, 
      { path: 'sensor/view', canActivate: [UserGuard], component: CartSensorComponent }, 
      { path: 'transaction/sensor', canActivate: [UserGuard], component: TransactionViewComponent }, 


      { path: 'empty', component: EmptyComponent }, 
      { path: '*', component: LoginComponent }, 
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

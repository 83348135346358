import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, AfterViewInit, HostListener, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HomeService } from 'src/app/service/home.service';
import { TransactionService } from 'src/app/service/transaction.service';
import { CommonService } from 'src/app/pages/common.service';
import { JsonService } from 'src/app/service/json.service';
import { environment } from 'src/environments/environment.prod';
import * as moment from 'moment';
// import { ChartsModule } from 'ng2-charts';
import { getMatFormFieldPlaceholderConflictError } from '@angular/material';
declare var $: any;
declare var videojs: any;
declare var jquery: any;
declare var JSMpeg: any;
//import * as data from '../../../assets/data.json';

import { BaseChartDirective } from 'ng2-charts/ng2-charts';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import 'hammerjs';
import 'chartjs-plugin-zoom';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  private URL = environment.BaseUrl + "services/cart";
  Sdate = new FormControl('', Validators.required);
  Edate = new FormControl('', Validators.required);
  tabs: any[];
  input = {
    fromdate: '',
    todate: '',
  };
  selectedTab: any;
  rowDataLogs = [];
  rowDataWaveAlarms = [];
  rowDataCustomAlarms = [];
  rowDataCustomCharts = [];
  logData: any;
  activeLogId: any;
  live: boolean = false;
  stDateErg: boolean = false;
  edDateErg: boolean = false;
  camId: string;
  transactionId: string;
  currentDate: string;
  tabIndex: any;
  tabClass = ['bg-primary', 'bg-success', 'bg-warning'];
  Tnotes = new FormControl('');
  usrName = localStorage.getItem('userName');
  player: any;
  refreshtab: number = 0;
  subscripe: any;
  sub2: any;
  liveChart: boolean = true;
  historyChart: boolean = true;
  stream: boolean = true;
  type: number = 1;
  index:any;
  // pageno = 1;
  // limit = 10;
  // offset = 0;
  players: any;
  mytimeout: any;
  canvas: any;
  ws1: any;
  destroys: any[] = [];
  urlmain: any;
  camlist: any[] = [];
  public SystemName: string = "Temperature";
  firstCopy = false;
  formGroup: FormGroup;
  invalidDate: boolean;
  // data
  public chartData?: Array<any> = [];
  // labels
  public lineLabels?:any = [];
  @ViewChild('streaming', { static: false }) streamingcanvas: ElementRef;

  @ViewChild('BaseChartDirective', { static: false }) chart: BaseChartDirective;

  endTimeData: any;
  actualTimeData: any;
  liveData:any = [];
  charHistory:Subscription;
  showChart:boolean = false;
  stopLoop:boolean=false;

  constructor(private formBuilder: FormBuilder, private homeService: HomeService, private transervice: TransactionService, private Commonservice: CommonService, private _ngZone: NgZone, public changeDetector: ChangeDetectorRef, private JsonService: JsonService) {
    window['angularComponentRef'] = {
      zone: _ngZone,
      componentFn: (value) => this.callFromOutside(),
      component: this
      //component: this, zone: _ngZone, componentFn: (value) => this.console(value)
    };
  }
  async callFromOutside() {
    this.updateLog(this.activeLogId);
    // this.zone.run(() => {
    // console.log('calledFromOutside ' + value);
    //  this.someValue = value;
    // });
  }
  streams(type) {
    this.live = false;
    if (type == 1) {
      this.type = 1
    } else {
      this.type = 2
    }
    this.stream = true;
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      Sdate: [this.input.fromdate, ''],
      Edate: [this.input.todate, ''],
    });
  }
  socketrefresh() {
    if (this.players) {
      this.streamingcanvas.nativeElement = null;
    } else {

    }
    if (window['url'] == "static") {
      this.socketinit('167.99.153.31', '8004', '167.99.153.31');
    } else {
      let newArr = this.camlist.filter((person: any) => { return person.name == this.selectedTab.cartname });
      if (newArr.length > 0) {
        // console.log(newArr[0].ipaddress);
        this.socketinit(newArr[0].ipaddress, newArr[0].port, newArr[0].cam_ipaddress);
      } else {
        // alert()
      }
    }
  }

  socketinit(ipaddress, port, cam_ipaddress) {
    var frameElement: any = document.getElementById("typef_orm_frame");
    frameElement.src = "./assets/ffmpeg/single.html?ip=" + ipaddress + "&port=" + port + "&cam_ipaddress=" + cam_ipaddress;
  }
  public ChartType = 'line';
  public lineChartOptions: any = {
    responsive: true,
    fill: false,
    scales: { xAxes: [{ display: false }] },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          // drag: true,
          mode: 'xy'
        },
        zoom: {
          enabled: true,
          // drag: true,
          mode: 'xy',
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
        }
      }
    }
  };


  public chartColors: Array<any> = [
    { // first color
      backgroundColor: 'rgba(225,10,24,0.5)',
      borderColor: 'rgba(225,10,24,0.5)',
      pointBackgroundColor: 'rgba(225,10,24,0.5)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(225,10,24,0.5)'
    },
    { // second color
      backgroundColor: 'rgba(102,16,242,0.5)',
      borderColor: 'rgba(102,16,242,0.5)',
      pointBackgroundColor: 'rgba(102,16,242,0.5)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(102,16,242,0.5)'
    }]

  async ngOnInit() {
    this.createForm();
    this.chartData = [];
    this.lineLabels = [];
    const resultList = await new Promise<any>((resolve, reject) =>
      this.JsonService.json().subscribe((data: any) => {
        resolve(data)
      })
    );

    if (resultList != null) {
      this.camlist = resultList;
    }
    $('.video-js').on('loadstart', function (event) {
      $(this).css('background', "black url('./assets/loader.gif') center center no-repeat");
    });
    $('.video-js').last().on('error', function () {
      $(this).css('background', "unset");
    });
    $('.video-js').on('canplay', function (event) {
      $(this).css('background', "unset");
    });
    // this.getTabs();
    this.subscripe = this.transervice.CamList({ site_id: environment.Site }, environment.Token).subscribe((res) => {
      this.tabs = res.data;
      if (res.data.length != 0) {
        this.tabClick(res.data[0]);
      } else {
        this.chartData = [];
        this.lineLabels = [];
      }
    });
  }

  liveCameraload() {
    this.live = true;
    this.stream = false;
    this.type = 0;
  }
  openHistoryChart() {
    this.showChart = false;
    this.liveChart = false;
    this.historyChart = false;
    if (this.charHistory){
      this.charHistory.unsubscribe();
    }
    this.input.fromdate = '';
    this.input.todate = '';
    this.formGroup.controls.Sdate.clearValidators();
    this.formGroup.controls.Edate.clearValidators();
    this.formGroup.reset();
    Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.controls[key].setErrors(null);
    });
    this.formGroup.patchValue({
      Sdate: '',
      Edate: '',
    });
  }
  openLiveChart() {
    this.showChart = false;
    this.liveChart = true;
    this.historyChart = true;
    if (this.charHistory){
      this.charHistory.unsubscribe();
    }
    this.liveChartsData();
    this.input.fromdate = '';
    this.input.todate = '';
    this.formGroup.controls.Sdate.clearValidators();
    this.formGroup.controls.Edate.clearValidators();
    this.formGroup.reset();
    Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.controls[key].setErrors(null);
    });
    this.formGroup.patchValue({
      Sdate: '',
      Edate: '',
    });
  }
  backMonitoring() {
    this.live = true;
    this.stream = true;
    this.type = 1;
  }

  liveCamera() {
    let _this = this;
    this.live = true;
    if (this.isValidateCamId()) {
      $('video').attr("src", "");
      if (window['url'] == "static") {
        this.socketinit('167.99.153.31', '8004', '167.99.153.31');
      } else {
        // console.log(this.selectedTab);
        // console.log(this.camlist);
        if (this.selectedTab) {
          if (this.camlist.length > 0) {
            let newArr = this.camlist.filter((person: any) => { return person.name == this.selectedTab.cartname });
            if (newArr.length > 0) {
              // console.log(newArr[0].ipaddress);
              this.socketinit(newArr[0].ipaddress, newArr[0].port, newArr[0].cam_ipaddress);
            } else {
            }
          } else {
          }
        }

      }
      // this.homeService.GetToken(this.camId, environment.Token).subscribe((data: any) => {
      //console.log(window['url'])
      //console.log(_this.selectedTab.waveIpaddress.split(':')[0])
      //this.socketinit(_this.selectedTab.waveIpaddress.split(':')[0]);
      //this.socketinit('167.99.153.31');
      // console.log("version");
      // videojs.Hls.xhr.beforeRequest = function (options) {
      //   options.headers = options.headers || {};
      //   // options.headers.Authorization = "";
      //   return options;
      // };
      // this.player = videojs('cam' + _this.camId, { liveui: true });
      // this.player.ready(function () {
      //   this.src({
      //     src: "http://" + _this.selectedTab.waveIpaddress + "/media/" + _this.camId + ".webm?auth=" + data.token,
      //     type: "video/webm",
      //   });
      // });
      // });
      this.createLog();
    }
  }

  createLog() {
    if (this.isValidateCamId()) {
      // this.mytimeout = setTimeout(function(){

      this.sub2 = this.homeService.CreateLog(this.camId, environment.Token).subscribe((data: any) => {
        this.activeLogId = data.id;
      });

      //},1000)

    }
  }

  onCommentsClick(e) {
    this.Tnotes.setValue(e.comments);
    this.activeLogId = e.id;
    $('#comment').modal('show');
  }

  openCmt() {
    this.Tnotes.setValue("");
    $('#comment').modal('show');
  }

  onImageClick(e) {

  }

  updateLog(activeLogId) {
    if (this.isValidateCamId()) {
      this.homeService.UpdateLog({ id: activeLogId }, environment.Token).subscribe((data: any) => {
        this.getLogs();
      });
    }
  }

  onBtExport() {
    this.homeService.downloadFile(this.rowDataLogs, "LogHistory-" + new Date());
  }

  addComments() {
    if (this.isValidateCamId()) {
      this.homeService.UpdateLog({ id: this.activeLogId, comments: this.Tnotes.value }, environment.Token).subscribe((data: any) => {
        this.Tnotes.setValue(data.comments);
        this.getLogs();
      });
    }
    this.Tnotes.setValue("");
    $('#comment').modal('hide');
  }

  getCustomAlarms() {
    if (this.isValidateCamId()) {
      this.homeService.GetAllAlerts(this.camId, environment.Token).subscribe(data => {
        this.rowDataCustomAlarms = data;
      })
    } else {
      this.rowDataCustomAlarms = [];
    }
  }
  checkInt(value) {
    let data;
    if (isNaN(+value)) {
      data = value;
    } else {
      data = Number(value).toFixed(2);
    }
    // console.log(data);
    return data;
  }
  getWaveAlarms() {
    if (this.isValidateCamId()) {
      this.homeService.GetWaveAlarms(this.camId, environment.Token).subscribe((data: any) => {
        this.rowDataWaveAlarms = JSON.parse(JSON.stringify(data)).reply;
      });
    } else {
      this.rowDataWaveAlarms = [];
    }

  }

  getLogs() {
    if (this.isValidateCamId()) {
      this.homeService.GetAllLogs(this.camId, environment.Token).subscribe((data: any[]) => {
        this.rowDataLogs = data;
        this.rowDataLogs.forEach((d, i) => {
          this.rowDataLogs[i].startTime = this.formatDate(d.startTime, false);
          this.rowDataLogs[i].endTime = this.formatDate(d.endTime, false);
          this.rowDataLogs[i].duration = this.datedif(d.startTime, d.endTime);
        });
      });
    } else {
      this.rowDataLogs = [];
    }
  }

  getWavBookmark(e) {
    var timeStamp = e.eventParams.eventTimestampUsec;
    var url = this.URL + "/getWaveBookmark/";
    window.open(url + this.camId + "/" + timeStamp);
  }

  isValidateCamId() {
    return true;
    //return this.camId.length > 20 ? true : false;
  }
  isValidateCartId() {
    // return true;
    return this.transactionId != '0' ? true : false;
  }
  formatDate(date, isUnixDate) {

    if (date != null && date != '' && date.length > 0) {
      // console.log(date)
      var dateonly = date.substring(0, 10).split('-');
      var timeonly = this.tConvert(date.substring(10, date.length).split('T')[1].substring(0, 8));

      date = dateonly[1] + '/' + dateonly[2] + '/' + dateonly[0] + ' ' + timeonly;

      // var a = isUnixDate ? new Date(date / 1000) : new Date(date);
      // return a.toLocaleDateString() + ' ' + a.toLocaleTimeString();
      return date;
    } else { return ''; }
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  datedif(date1, date2) {
    if (date2 != 'null' && date2 != null && date2 != '') {
      let dur = ((new Date(date2).getTime() / 1000 - new Date(date1).getTime() / 1000) / 60).toFixed(2);
      return dur;
    } else { return ''; }
  }

  logHistory() {
    this.live = false;
    this.stream = false;
    this.type = 0;
    // this.updateLog(this.activeLogId);
    this.getLogs();
  }

  getTabs() {
    this.homeService.GetAllTabs(environment.Token).subscribe((data: any) => {
      this.tabs = data;
      this.tabClick(this.tabs[0])
    });
  }

  tabClick(tab) {
    this.rowDataCustomAlarms = [];
    this.rowDataCustomCharts = [];
    this.rowDataWaveAlarms = [];
    this.rowDataLogs = [];
    this.selectedTab = tab;
    this.transactionId = tab ? tab.transactionId : '0';
    this.camId = tab ? tab.camId : 0;
    var activeLogId = this.activeLogId;
    this.activeLogId = "";
    if (activeLogId) { this.updateLog(activeLogId); }
    this.live = true;
    this.showChart = false;
    this.historyChart = true;
    this.liveChart = true;
    this.liveCamera();
    if (this.charHistory){
      this.charHistory.unsubscribe();
    }
   
    this.liveChartsData();
    if ($('.events-body').css('display') == 'none') { this.getCustomAlarms(); } else { this.getWaveAlarms(); }
    this.currentDate = this.formatDatemy(new Date().toLocaleString('en-US', { timeZone: 'America/Denver' }), false);
  }
  async liveChartsData() {
    this.chartData = [];
    this.lineLabels = [];
    this.rowDataCustomCharts = [];
    if (this.isValidateCartId()) {
      this.charHistory = this.homeService.GetLiveChartsDefault(this.transactionId, environment.Token).subscribe(data => {
        this.rowDataCustomCharts = data.data;
        var index = 0;
        let fisrtArrayLength = 0;
        let maxArray =[];
        if (this.rowDataCustomCharts.length != 0) {
          if (this.rowDataCustomCharts[0].temperatureValues.length != 0){
            fisrtArrayLength = this.rowDataCustomCharts[0].temperatureValues.length;
          }
          this.rowDataCustomCharts.forEach((d, i) => {
            this.liveData = [];
            let maxValue = Math.max.apply(Math, d.temperatureValues.map(function (o) { return o.id; }));
            maxArray.push(maxValue);
            d.temperatureValues.forEach((b, j) => {     
                if (fisrtArrayLength > j){
                  this.liveData.push(b.fhTemp);
                }
                if (index == 0) {
                  var stillUtc = moment.utc(this.formatDate(b.dateTime, false)).toDate();
                  var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                  this.lineLabels.push(local);
                }
            });
            index++;
            this.chartData.push({ 'label': d.tagName, 'data': this.liveData, fill: false, })
          });
          this.index = Math.max.apply(Math, maxArray.map(function (p) { return p; }));
          this.setChartColors();
          this.showChart = true;
          this.stopLoop=true;
          setTimeout(() => {
            if(this.stopLoop){
              this.liveChartsDataContinue();
            }            
          }, 30000);
        }
      });
    }
  }

  setChartColors() {
    this.chartColors = [
      { // first color
        backgroundColor: '#fff',
        borderColor: 'rgba(225,10,24,0.5)',
        pointBackgroundColor: 'rgba(225,10,24,0.5)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(225,10,24,0.5)'
      },
      { // second color
        backgroundColor: '#fff',
        borderColor: 'rgba(102,16,242,0.5)',
        pointBackgroundColor: 'rgba(102,16,242,0.5)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(102,16,242,0.5)'
      },
      { // third color
        backgroundColor: '#fff',
        borderColor: 'rgba(78,115,223,0.5)',
        pointBackgroundColor: 'rgba(78,115,223,0.5)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(78,115,223,0.5)'
      },
      { // fourth color
        backgroundColor: '#fff',
        borderColor: 'rgba(28,200,138,0.5)',
        pointBackgroundColor: 'rgba(28,200,138,0.5)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(28,200,138,0.5)'
      }];
  }
  async getTheRecord() {
    this.chartData = [];
    this.lineLabels = [];
    this.rowDataCustomCharts = [];
    if (this.isValidateCartId()) {
      this.stopLoop=false;
      this.charHistory = this.homeService.GetHistoryCharts(this.transactionId, this.input, environment.Token).subscribe(data => {
        this.rowDataCustomCharts = data.data;
        var index = 0;
        let fisrtArrayLength = 0;
        if (this.rowDataCustomCharts.length != 0) {
          if (this.rowDataCustomCharts[0].temperatureValues.length != 0){
            fisrtArrayLength = this.rowDataCustomCharts[0].temperatureValues.length;
          }
          this.rowDataCustomCharts.forEach((d, i) => {
            let data = [];
            d.temperatureValues.forEach((b, j) => {
              if (fisrtArrayLength > j){
                this.liveData.push(b.fhTemp);
              }
              if (index == 0) {
              data.push(b.fhTemp);
              var stillUtc = moment.utc(this.formatDate(b.dateTime, false)).toDate();
              var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
              this.lineLabels.push(local);
              }
            });
            this.chartData.push({ 'label': d.tagName, 'data': data });
          });
          this.showChart = true;
          this.setChartColors();
        }
      })

    }
  }

  checkDates() {
    console.log(this.formGroup.controls.Sdate.value);
    console.log(this.formGroup.controls.Edate.value);
    let fromDate = this.formGroup.controls.Sdate.value;
    let toDate = this.formGroup.controls.Edate.value;
    this.stDateErg = false
    this.edDateErg = false
    this.invalidDate = false
    if(!moment(fromDate).isValid() || !moment(toDate).isValid()){ 
      if(fromDate !='' && !moment(fromDate).isValid()){
        this.stDateErg = true;
      }  
      if(toDate !='' && !moment(toDate).isValid()){
        this.edDateErg = true;
      } 
    } else if (toDate !='' && fromDate !='') {
     if (new Date(fromDate) > new Date(toDate)) {
        this.invalidDate = true;
      } else {
        this.input.fromdate = moment(this.formGroup.controls.Sdate.value).utc().format("YYYY-MM-DD HH:mm:ss");
        this.input.todate = moment(this.formGroup.controls.Edate.value).utc().format("YYYY-MM-DD HH:mm:ss");
        this.rowDataCustomCharts = [];
        if (this.charHistory){
          this.charHistory.unsubscribe();
        }
        this.getTheRecord();
      }
    }
    if (toDate=='' || fromDate =='') {
      this.formGroup.controls.Sdate.setValidators([Validators.required]);
      this.formGroup.controls.Edate.setValidators([Validators.required]);
      this.formGroup.controls.Sdate.updateValueAndValidity();
      this.formGroup.controls.Edate.updateValueAndValidity();
    } else {
      this.formGroup.controls.Sdate.clearValidators();
      this.formGroup.controls.Edate.clearValidators();
    }
  }
  formatDatemy(date, isUnixDate) {
    if (date != null && date != '') {
      var a = isUnixDate ? new Date(date / 1000) : new Date(date);
      return a.toLocaleDateString() + ' ' + a.toLocaleTimeString();
      return date;
    } else { return ''; }
  }

  AlarmClick() {
    this.refreshtab = 0;
    $('.events-body').css("display", "none");
    $('.alarms-body').css("display", "block");
    this.getCustomAlarms();
  }
  EventClick() {
    this.refreshtab = 1;
    $('.events-body').css("display", "block");
    $('.alarms-body').css("display", "none");
    this.getWaveAlarms();
  }

  ngAfterViewInit() {
    var _this = this;
    $('.cus-tab').click(function () {
      $('.cus-tab').removeClass('cus-tab-selected')
      $(this).addClass('cus-tab-selected')
    });
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
    if (this.activeLogId) {
      this.updateLog(this.activeLogId);
    }
    $event.returnValue = "";
  }

  ngOnDestroy() {

    // console.log(this.sub2)
    //window.angularComponent = null;
    if (this.player) {
      this.player.dispose();
    }

    // if (this.players) {
    //   this.players.destroy();
    // }
    // if(this.destroys)
    // {
    //   if(this.destroys.length > 0)
    //   {
    //     this.destroys.forEach(resource => {
    //       resource.destroy();
    //    });
    //   }
    // }




    clearTimeout(this.mytimeout)

    if (this.subscripe) {
      this.subscripe.unsubscribe()
    }

    //$(".video-js").first().attr('src','')
    // var videoElement = $('.video-js')
    // videoElement[0].pause()  // Pause video
    // videoElement.empty()     // Remove all <source> children
    // videoElement.load()      // Load the now sourceless video
    // //delete videoElement      // The call mentioned in other answers
    // videoElement.remove()    // Removing the video element altogether
    if (this.activeLogId) {
      this.updateLog(this.activeLogId);
    }

  }

  refresh() {
    this.currentDate = this.formatDatemy(new Date().toLocaleString('en-US', { timeZone: 'America/Denver' }), false);
    if (this.refreshtab == 0) {
      this.getCustomAlarms();
    } else {
      this.getWaveAlarms();
    }

  }

  // current(val) {
  //   this.pageno = val;
  //   this.limit=this.pageno-1;
  //   this.getCustomAlarms()
  // }

  // PerPage(val) {
  //   this.limit = val;
  //   this.pageno = 1;
  //   this.offset=this.pageno-1;
  // }

  async liveChartsDataContinue() {

    this.rowDataCustomCharts = [];    
  
    if (this.isValidateCartId()) {
      this.charHistory = this.homeService.GetLiveChartsLoop(this.transactionId, this.index, environment.Token).subscribe(data => {
        this.rowDataCustomCharts = data.data;
        var index = 0;
        let fisrtArrayLength = 0;
        let maxArray = [];
        if (this.rowDataCustomCharts.length != 0) {
          if (this.rowDataCustomCharts[0].temperatureValues.length != 0) {
            fisrtArrayLength = this.rowDataCustomCharts[0].temperatureValues.length;
          }
          this.rowDataCustomCharts.forEach((d, i) => {    
           let maxValue = Math.max.apply(Math, d.temperatureValues.map(function (o) { return o.id; }));
            maxArray.push(maxValue);
            this.chartData[index].data.splice(0, fisrtArrayLength);
            if (index == 0) {
              this.lineLabels.splice(0, fisrtArrayLength);
            }
            d.temperatureValues.forEach((b, j) => { 
              if (fisrtArrayLength > j) {
              this.chartData[index].data.push(b.fhTemp);
              }
              if (index == 0) {
                var stillUtc = moment.utc(this.formatDate(b.dateTime, false)).toDate();
                var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                this.lineLabels.push(local);
              }              
            }); 
            index++;                     
          });

          if (this.chart && this.chart.chart && this.chart.chart.config) {
            this.chart.chart.config.data.labels = this.lineLabels;
            this.chart.chart.config.data.datasets = this.chartData;
            this.chart.chart.update();
          }
          this.index = Math.max.apply(Math, maxArray.map(function (p) { return p; }));
          setTimeout(() => {
            if(this.stopLoop){
              this.liveChartsDataContinue();
            }            
          }, 30000);
        }
      })
    }
  }
  resetZoomCall(){
    this.chart.chart.update();
    
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login.service';
import { environment } from 'src/environments/environment.prod';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup: FormGroup;
  invalid: string;
  constructor(private service: LoginService,private router: Router,private formBuilder: FormBuilder, private usrservice: UserService,) {
    if(localStorage.getItem("token")){this.router.navigate(['/home']);}
  }

  ngOnInit(): void {
    this.LoginForm()
  }

  LoginForm() {
    this.formGroup = this.formBuilder.group({
		username : ['', Validators.required], 
		password: ['', [Validators.required]],   
    });
  }

  Login(){ 
    this.invalid='';
    if (this.formGroup.invalid)
    {
      this.formGroup.get('username').markAsTouched(); 
      this.formGroup.get('password').markAsTouched(); 
    }
    else
    { 
      // var Auth = {
      //   username: 'test@gmail.com',
      //   password: 'test@gmail.com'
      // }
      // this.service.Login(Auth).subscribe((res)=>{  
      //   if(res.token){
      //     localStorage.setItem("auth",res.token); 
      //     // this.router.navigate(['/home']); 
      //   }
      // },(error) => {                               
      //   if(error.status == 401){this.invalid="Invalid UserName or Password."}
      // });
      var input = {
        username: this.formGroup.controls.username.value,
        password: this.formGroup.controls.password.value
      }
      localStorage.setItem("userName",''+this.formGroup.controls.username.value); 
      this.service.LoginToken(input).subscribe((val)=>{ 
        if (val.message == "Inactive user trying to login ") { 
          this.invalid="You are inactive user.";
        }
        if (val.message == "Success") { 
          environment.Token=val.token;
          environment.Role=val.role;
          localStorage.setItem("token",val.token); 
          localStorage.setItem("role", val.role); 
          this.usrservice.SiteList(val.token).subscribe((sit) => {
            if(sit.data.length!=0){
              environment.Site=sit.data[0].id;
              localStorage.setItem("site",""+sit.data[0].id); 
            }
            this.router.navigate(['/home']); 
          });
        }
        if (val.message == "Invalid Parameters"||val.message=="Something Went Wrong") { this.invalid="Invalid UserName or Password."; }
      });
      
    }
  }

}

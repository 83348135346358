import { Injectable,EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs/internal/Subscription';


@Injectable({
  providedIn: 'root'
})

export class CartService {
  cartUpdateCondtion= new EventEmitter();
  subsVar: Subscription;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + environment.Token })
  };;
  URL: any;
  constructor(private http: HttpClient) { this.URL = environment.ApiUrl }
  onCartUpdateCondtion(cond:boolean){
    this.cartUpdateCondtion.emit(cond);
  }
  CartIndex(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/cartindex', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  CartAdd(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/addcart', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  CartView(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/cartview', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
  CartUpdate(input: any,token): Observable<any> {
    return this.http.post(this.URL + '/editcart', input, {headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})})
  }
}
